import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { GetDataWithToken } from "../ApiHelper/ApiHelper";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import TableComponent from "../Components/Layouts/TableComponent";
import OrderModal from "../Components/Modals/OrderModal";
import PageLoader from "../Components/Common/PageLoader";

const Invoices = () => {
    const [pageLoading, setPageLoading] = useState(false);
    const [tabOpen, setTabOpen] = useState("1");
    const [callApi, setCallApi] = useState(true);
    const [invoiceData, setInvoiceData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, settotalPage] = useState(1);
    const [invoiceType, setInvoiceType] = useState('INVOICES');
    const [searchData, setSearchData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [customerCode, setCustomerCode] = useState('');
    const [deliveryName, setDeliveryName] = useState('');
    const [date, setDate] = useState({
        fromDate: '',
        toDate: '',
    });

    const { ref: myRef, inView: visibleElement } = useInView();
    const modalToggle = () => setOpenModal(!openModal);

    const setTabValue = (value) => {
        if (value === tabOpen) {
            return
        }
        setTabOpen(value);
        tabOpen === "1" ? setInvoiceType('CREDIT_NOTE') : setInvoiceType('INVOICES');
        setCallApi(true);
        setInvoiceData([]);
        setIsLoading(true);
        setCurrentPage(1);
    }

    const tableHeading = ["Code", "Date", "Delivery Name",
        //  "Heading"
    ];


    const filters = [];

    if (searchData) {
        filters.push(`SearchText:${searchData}`);
    }

    if (customerCode) {
        filters.push(`CustomerCode:${customerCode}`);
    }
    if (deliveryName) {
        filters.push(`DeliveryName:${deliveryName}`);
    }
    if (invoiceType) {
        filters.push(`TypeCode:${invoiceType}`);
    }
    if (date?.fromDate !== null && date?.fromDate !== undefined) {
        filters.push(`FromDate:${date?.fromDate}`);
    }

    if (date?.toDate !== null && date?.toDate !== undefined) {
        filters.push(`ToDate:${date.toDate}`);
    }

    const queryString = filters.length > 0 ? `invoice/list?pageNo=${currentPage}&pageSize=10&filters=${filters.join(';')}` : `invoice/list?pageNo=${currentPage}&pageSize=10`;

    useEffect(() => {
        if (callApi || visibleElement) {
            setIsLoading(true);
            GetDataWithToken(
                queryString
                // `invoice/list?pageNo=${currentPage}&pageSize=10&filters=SearchText:${searchData}%3BTypeCode%3A${invoiceType};CustomerCode:${customerCode};FromDate:${date?.fromDate ? date?.fromDate : ""};ToDate:${date?.toDate ? date?.toDate : ""};DeliveryName:${deliveryName}`
            )
                .then((response) => {
                    setInvoiceData(prevData => [...prevData, ...response]);
                    setCurrentPage((prevPage) => prevPage + 1);
                    setPageLoading(false);
                    setCallApi(false);
                    setIsLoading(false);
                })
        }
    }, [callApi, visibleElement])

    return (
        <>
            <Layout>
                <Heading
                    name='Posted Invoices'
                    // breadCrumb1="invoices"
                    searchData={searchData}
                    setSearchData={setSearchData}
                    mainSetCallApi={setCallApi}
                    modalToggle={modalToggle}
                    setMainData={setInvoiceData}
                    setCurrentPage={setCurrentPage}
                    setPageLoading={setPageLoading}
                />
                <div className="card">
                    <Nav tabs fill pills>
                        {!isLoading && <>
                            <NavItem>
                                <NavLink
                                    style={{ fontWeight: "600" }}
                                    className={tabOpen === "1" ? "active" : ""}
                                    onClick={() => setTabValue('1')}
                                >
                                    INVOICE
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ fontWeight: "600" }}
                                    className={tabOpen === "2" ? "active" : ""}
                                    onClick={() => setTabValue('2')}
                                >
                                    CREDIT-NOTE
                                </NavLink>
                            </NavItem>
                        </>}
                    </Nav>
                    <TabContent activeTab={tabOpen}>
                        <TabPane tabId="1">
                            <div className="table-responsive">
                                <TableComponent data={invoiceData}
                                    isLoading={isLoading}
                                    navigation='/invoice-detail'
                                    tableHeading={tableHeading} />
                                {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="table-responsive">
                                <TableComponent
                                    data={invoiceData}
                                    isLoading={isLoading}
                                    navigation='/invoice-detail'
                                    tableHeading={tableHeading} />
                                {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
                            </div>
                        </TabPane>
                    </TabContent>
                </div>


                {invoiceData?.length >= 9 && !isLoading && <div ref={myRef} id="scroll"></div>}
            </Layout>
            <OrderModal
                openModal={openModal}
                modalToggle={modalToggle}
                setCustomerCode={setCustomerCode}
                customerCode={customerCode}
                mainCallApi={setCallApi}
                setDate={setDate}
                date={date}
                setDeliveryName={setDeliveryName}
                deliveryName={deliveryName}
                setIsLoading={setIsLoading}
                setMainData={setInvoiceData}
                setCurrentPage={setCurrentPage}
            />
            {pageLoading && <PageLoader />}
        </>
    )
};
export default Invoices;