import {
  CHECK_USER_LOGGED_IN,
  USER_LOGGED_IN_DETAILS,
  FETCH_USER_REQUEST,
  USER_TYPE,
  CART_DATA,
  SEARCH_DATA
} from "../types";

const initialState = {
  userType: null,
  loading: false,
  isLoggedIn: false,
  userDetails: {},
  cartData: [],
  searchData: null,

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CART_DATA: {
      return {
        ...state,
        cartData: action.payload,
      };
    }
    case SEARCH_DATA: {
      return {
        ...state,
        searchData: action.payload,
      };
    }
    case USER_TYPE: {
      return {
        ...state,
        userType: action.payload,
      };
    }
    case USER_LOGGED_IN_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case CHECK_USER_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
