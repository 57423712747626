import { useEffect, useState } from "react";
import Layout from "../Components/Common/Layout";
import { useForm } from "react-hook-form";
import { GetDataWithToken, PostDataWithToken } from "../ApiHelper/ApiHelper";
import Heading from "../Components/Layouts/Heading";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const MyProfile = () => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    let userData = useSelector((store) => store?.user?.userDetails);
    let userName = useSelector((store) => store?.user?.userType);
    // console.log("dskav............", userName);

    const logoutHandler = () => {
        Cookies.remove("F&ftToken");
        navigate("/");
    };

    const onSubmit = (profdata) => {
        // console.log(data);
        const submitData = {
            Active: 1, City: null,
            Code: null,
            ConfirmPassword: profdata?.confirmPassword,
            CreatedById: null,
            CreatedOn: null, DefaultCompany: userData?.DefaultCompany,
            Email: userData?.email,
            ErpCode: userData?.ErpCode, Id: 0, LastModifiedById: null,
            LastModifiedOn: null, Locations: null,
            MobileNo: "",
            Name: userData?.Name,
            Password: profdata?.password,
            PasswordHash: null,
            PictureUrl: null,
            RefreshTokenLifeTime: 0,
            Role: userData?.Role,
            RowVersion: 0,
            SalesPerson: null, SocialSigninProvider: null,
            SocialUserId: null, StateId: null,
            UserCompanyName: null,
            UserName: userName,
        }
        PostDataWithToken(`user/change-password`, submitData)
            .then((response) => {
                toast(response, {
                    position: "top-right",
                });
                navigate("/home");
            })
    }

    useEffect(() => {
        GetDataWithToken(`user/details?userName=${userName}`)
            .then(response => {
                setData(response);
                // console.log("heyyy... dataa....", response);
            })
    }, [])

    return (<Layout>
        <Heading name="Edit Profile"
        // breadCrumb1={"Profile"}
        />
        <div className="content-body" >
            <div className="row">
                <div class="login-area-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="login-form-profile">
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="email"
                                            placeholder={`Name ${data?.Name}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-user'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="email"
                                            placeholder={`user name ${data?.UserName}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-user'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="mobile"
                                            placeholder={`Mobile No ${data?.MobileNo}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-mobile'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="mobile"
                                            placeholder={`Email ${data?.Email}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-mail-send'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="mobile"
                                            placeholder={`Company ${data?.DefaultCompany}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-current-location'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="mobile"
                                            placeholder={`City ${data?.City}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bxs-city'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="mobile"
                                            placeholder={`State ${data?.StateId}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-globe'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="mobile"
                                            placeholder={`ERPCode ${data?.ErpCode}`}
                                            //  {...register("email", {
                                            //    required: true,
                                            //    maxLength: 80,
                                            //  })}
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-at'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password"
                                            class="form-control"
                                            name="text"
                                            placeholder={`Role ${data?.Role}`}
                                            //   {...register("password", {
                                            //     required: true,
                                            //      maxLength: 80,
                                            //   })} 
                                            disabled
                                        />
                                        <span class="label-title"><i class='bx bx-award'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password"
                                            class="form-control"
                                            name="password"
                                            placeholder="Password"
                                            {...register("password", {
                                                required: true,
                                                maxLength: 80,
                                            })}
                                        />
                                        <span class="label-title"><i class='bx bx-lock'></i></span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control" name="password"
                                            placeholder="Confirm Password"
                                            {...register("confirmPassword", {
                                                required: true,
                                                maxLength: 80,
                                            })}
                                        />
                                        <span class="label-title"><i class='bx bx-lock'></i></span>
                                    </div>
                                    <button type="submit" class="btn btn-primary">save</button>
                                </form>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <button
                                        class="btn btn-pirmary"
                                        style={{ display: "flex", alignItems: "center" }}
                                        onClick={logoutHandler}
                                    >
                                        <i class="bx bx-log-out pe-1"></i> <span>Logout</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>)
}
export default MyProfile;