import { Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { GetDataWithToken } from "../ApiHelper/ApiHelper";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
const OrderDetails = () => {
    const location = useLocation();
    // console.log('looooooooooo', location);
    const [orderData, setOrderData] = useState();
    useEffect(() => {
        GetDataWithToken(`so/details?code=${location.state.id}`).then((response) => {
            setOrderData(response);
        })
    }, [])
    return (
        <Layout>
            {/* <div className={styles.container} >
                <div className={styles.col_1}>
                    <div className={styles.list}>
                         <ul class="list-group list-group-flush">
                                                <li class="list-group-item d-flex justify-content-between">                                          
                                                    <strong>Order No.</strong>
                                                    <span class="mb-0">{ orderData?.Code  }</span>
                                                </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Order Date.</strong>
                                                    <span class="mb-0">{
                                                        orderData?.OrderDate&& moment( orderData?.OrderDate)?.format('DD/MM/YYYY')
                                                            
                                                    }
                                                    </span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                   
                                                    <strong>Sales person Contact</strong>
                                                    <span class="mb-0">{ orderData?.SalesPersonContact }</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Remarks.</strong>
                                                    <span class="mb-0">{ orderData?.Remarks }</span>
                                            </li>
                                                
                                               <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Delivery Mode</strong>
                                                    <span class="mb-0">{ orderData?.DeliveryMode }</span>
                                            </li>
                        </ul>
                    </div>
                    <div className={styles.list}>
                        <ul class="list-group list-group-flush">
                                                <li class="list-group-item d-flex justify-content-between ">                                          
                                                    <strong>Customer No.</strong>
                                                    <span class="mb-0">{ orderData?.CustomerCode }</span>
                                                </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Sales Person Name</strong>
                                                    <span class="mb-0">{ orderData?.SalesPersonName }</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                   
                                                    <strong>Sales Person Email</strong>
                                                    <span class="mb-0">{ orderData?.SalesPersonEmail }</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    
                                                    <strong>Transport</strong>
                                                    <span class="mb-0">{orderData?.Transport?.Code}</span>
                                                    <span class="mb-0">{orderData?.Transport?.Name}</span>
                                            </li>
                                                
                                               <li class="list-group-item d-flex justify-content-between">                                                 
                                                    <strong>Delivery Name</strong>
                                                    <span class="mb-0">{ orderData?.DeliveryName }</span>
                                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.col_2}>
                    <div><h3>Items:</h3></div>
                    <div>
                       <Table bordered>
                                          <thead>
                                             <tr>
                                               <th>
                                               item
                                            </th>
                                            <th>
                                               ordered
                                            </th>
                                            <th>
                                               pending
                                            </th>
                                            <th>
                                               Shipping date
                                                    </th>
                                                    <th>
                                              status
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                         {orderData?.Lines?.map((data) =>
                                           < tr >
                                            <td>
                                              {data?.ItemName}
                                            </td>
                                            <td>
                                                     {data?.Qty&&+data?.Qty?.toFixed(2)}
                                                     
                                            </td>
                                            <td>
                                               {data?.QtyPending && +data?.QtyPending.toFixed(2)}
                                            </td>
                                            <td>
                                               {data?.ShippingDate&&moment(data?.ShippingDate)?.format("DD/MM/YYYY")}
                                             </td>
                                            <td>
                                                {data?.Status}
                                            </td>
                                            </tr>
                                           )}
                                        </tbody>
                                        </Table>
                    </div> 
                </div>
           </div> */}
            <div className="content-body">
                <div className="container-fluid px-0">
                    {/* <Heading 
                        name="Order details"
                        // breadCrumb1={"orders"}
                        // breadCrumb2={"order-detail"} 
                        /> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-2">

                                <div class="row">
                                    <div class="col-lg-6">
                                        <ul class="list-group mb-lg-0 mb-2">
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <strong>Order No.</strong>
                                                <span class="mb-0">{orderData?.Code}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">

                                                <strong>Order Date.</strong>
                                                <span class="mb-0">{
                                                    orderData?.OrderDate && moment(orderData?.OrderDate)?.format('DD/MM/YYYY')

                                                }
                                                </span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">

                                                <strong>Sales person Contact</strong>
                                                <span class="mb-0">{orderData?.SalesPersonContact}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">

                                                <strong>Remarks.</strong>
                                                <span class="mb-0">{orderData?.Remarks}</span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between">

                                                <strong>Delivery Mode</strong>
                                                <span class="mb-0">{orderData?.DeliveryMode}</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col-lg-6">
                                        <ul class="list-group">
                                            <li class="list-group-item d-flex justify-content-between ">
                                                <strong>Customer No.</strong>
                                                <span class="mb-0">{orderData?.Customer?.Code}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">

                                                <strong>Sales Person Name</strong>
                                                <span class="mb-0">{orderData?.SalesPersonName}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">

                                                <strong>Sales Person Email</strong>
                                                <span class="mb-0">{orderData?.SalesPersonEmail?.split(';')?.map((data) => <span style={{ justifyContent: "end",display:"block" }}>{data}</span>)}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">

                                                <strong>Transport</strong>
                                                
                                                <span class="mb-0">{orderData?.Transport?.Name}</span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between ">
                                                <strong>Delivery Name</strong>
                                                <span class="mb-0">{orderData?.DeliveryName}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-lg-3 my-4">
                                        <h4 className="card-title">items :</h4>
                                    </div>
                                    <div className="table-responsive">
                                    <Table className="mt-2 table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>
                                                    item
                                                </th>
                                                <th>
                                                    ordered
                                                </th>
                                                <th>
                                                    pending
                                                </th>
                                                <th>
                                                    Shipping date
                                                </th>
                                                <th>
                                                    status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData?.Lines?.map((data) =>
                                                < tr >
                                                    <td>
                                                        {data?.ItemName}
                                                    </td>
                                                    <td>
                                                        {data?.Qty && +data?.Qty?.toFixed(2)}

                                                    </td>
                                                    <td>
                                                        {data?.QtyPending && +data?.QtyPending.toFixed(2)}
                                                    </td>
                                                    <td>
                                                        {data?.ShippingDate && moment(data?.ShippingDate)?.format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                        {data?.Status}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default OrderDetails;