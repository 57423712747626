import { useEffect, useState } from "react";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { GetDataWithToken, serverUrl } from "../ApiHelper/ApiHelper";

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const photoData = [];
  const [data, setData] = useState([]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    GetDataWithToken(`other/slideImages?pageNo=1&pageSize=50`).then(
      (response) => {
        setData(response);
      }
    );
    GetDataWithToken(`api/items/common-details?code=https://scan.fandf.in/yXWs?qr=1`)
  }, []);

  const slides =
    data?.length > 0 &&
    data?.map((item) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.ImageName}
        >
          <div style={{ height: "300px" }}>
            <img
              src={`${serverUrl}Images/Slides/${item.ImageName}.jpg`}
              width="100%"
              height="50px"
            />
          </div>
          {/* <CarouselCaption
          captionText={"hello"}
          captionHeader={"1"}
        /> */}
        </CarouselItem>
      );
    });

  return (
    <Layout>
      {/* <Heading name="Home" /> */}
      <div className="container-fluid px-0">
        <div className="card">
          {data.length > 0 && (
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className=""
            // {...args}
            >
              <CarouselIndicators
                items={data}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          )}

          <div className="row">
            <div class="col-lg-6 col-md-6  mt-4">
              <p>BOOKS</p>
              <img src={`${serverUrl}Images/Categories/BOOKS.jpg`} />
            </div>
            <div class="col-lg-6 col-md-6  mt-4">
              <p>FABRICS</p>
              <img src={`${serverUrl}Images/Categories/FABRIC.jpg`} />
            </div>
            <div class="col-lg-6 col-md-6  mt-4">
              <p>WALLPAPER</p>
              <img src={`${serverUrl}Images/Categories/wallpaper.jpg`} />
            </div>
            <div class="col-lg-6 col-md-6  mt-4">
              <p>WTW CARPET</p>
              <img src={`${serverUrl}Images/Categories/WTW%20Carpet.jpg`} />
            </div>
            <div class="col-lg-6 col-md-6  mt-4">
              <p>CARPET TILE</p>
              <img src={`${serverUrl}Images/Categories/Carpet%20Tile.jpg`} />
            </div>
            <div class="col-lg-6 col-md-6  mt-4">
              <p>LINEN</p>
              <img src={`${serverUrl}Images/Categories/LINEN.jpg`} />
            </div>
            <div class="col-lg-6 col-md-6  mt-4">
              <p>RUGS</p>
              <img src={`${serverUrl}Images/Categories/RUGS.jpg`} />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </Layout>
  );
};

export default Home;
