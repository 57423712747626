import axios from "axios";
import Cookies from "js-cookie";

// Api Url's for the API's
export const serverUrl = "https://tradeapi.fandf.in/";
// export const serverUrl = "http://192.168.1.48:45456/"

// Api's Function
export function PostData(url, data) {
  // body..
  //
  var headers = {
    "Content-Type": "application/json",
    "X-localization": "en",
  };
  return axios
    .post(serverUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export const PostDataWithToken = (url, data) => {
  let tokens = "";
  if (Cookies.get("F&ftToken")) {
    tokens = Cookies.get("F&ftToken");
  }
  var headers = {
    Authorization: "Bearer " + tokens,
    "Accept-Language": "en",
  };
  return axios
    .post(
      serverUrl + url,
      data,
      { headers: headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        let errorStatus = JSON.parse(JSON.stringify(error.response));
        return errorStatus;
      }
    });
};

export const GetDataWithToken = (url, cancelTokenParam) => {

  // const cancelToken = cancelTokenParam;
  let tokens = "";
  if (Cookies.get("F&ftToken")) {
    tokens = Cookies.get("F&ftToken");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      "Accept-Language": "en",
    },
    params: {},
    cancelToken: cancelTokenParam,
  };
  return axios
    .get(serverUrl + url, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        let errorStatus = JSON.parse(JSON.stringify(error.response));
        return errorStatus;
      }
    });
};

export function PutDataWithToken(url, data) {
  const cancelToken = axios.CancelToken.source();
  let tokens = "";
  if (Cookies.get("F&ftToken")) {
    tokens = Cookies.get("F&ftToken");
  }
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + tokens,
    Accept: "application/json",
  };
  return axios
    .put(
      serverUrl + url,
      data,
      { headers: headers },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        let errorStatus = JSON.parse(JSON.stringify(error.response));
        return errorStatus;
      }
    });
}
