import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import { GetDataWithToken } from "../../ApiHelper/ApiHelper";
const SearchStockModal = ({ modalToggle, modalOpen, searchBrandData, setSearchBrandData, mainSetCallApi, searchCollectionData, setSearchCollectionData, categoryCode, setMainData, setCurrentPage }) => {

    const [nextPage, setNextPage] = useState(0);
    const [brandData, setBrandData] = useState([]);
    const [collectionData, setCollectionData] = useState([]);
    const [callApi, setCallApi] = useState(false);
    const [callApi2, setCallApi2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toggle = () => {
        modalToggle();
        setNextPage(0);
    }

    const submitSearchData = () => {
        setMainData([]);
        // setIsLoading(true);
        toggle();
        setCurrentPage(1);
        mainSetCallApi(true);
    }

    const handleInputBrandChange = (e) => {
        setSearchBrandData(e.target.value);
        setCallApi(true);
    }
    const handleInputCollectionChange = (e) => {
        setSearchCollectionData(e.target.value);
        setCallApi2(true);
    }

    const brandSubmitHandler = (data) => {
        // console.log("dataaa",data);
        setSearchBrandData(data);
        setNextPage(0);
    }

    const collectionSubmitHandler = (data) => {
        setSearchCollectionData(data);
        setNextPage(0);
    }

    useEffect(() => {
        if (callApi) {
            setIsLoading(true);
            GetDataWithToken(`api/items/brands?filters=SearchText:${searchBrandData}&pageNo=1&pageSize=50`).then((response) => {
                setBrandData(response);
                setIsLoading(false);
                setCallApi(false);
            })
        }
        if (callApi2) {
            setIsLoading(true);
            GetDataWithToken(`api/items/collections?filters=SearchText:${searchCollectionData}&pageNo=1&pageSize=50`).then((response) => {
                setCollectionData(response);
                setIsLoading(false);
                setCallApi2(false);
            })
        }
    }, [callApi, callApi2])

    return (
        <Modal isOpen={modalOpen} toggle={toggle} centered>
            {nextPage === 0 && <>
                <ModalHeader toggle={toggle}>
                    FILTERS
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <p className="ms-2 d-flex align-items-center justify-content-between" >
                            <button
                                className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                                onClick={() => { setNextPage(1); setCallApi(true) }}
                                type="button">Brand</button>
                            <p className="mb-0 d-flex align-items-center text-nowrap">{searchBrandData && ` ${searchBrandData}`}
                                {searchBrandData && <button className="btn btn-primary ms-2" onClick={() => setSearchBrandData('')}>X</button>}
                            </p>
                        </p>

                    </div>
                    <hr></hr>
                    <p className="ms-2 d-flex align-items-center justify-content-between"
                    >
                        <button
                            onClick={() => { setNextPage(2); setCallApi2(true) }}
                            className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                            type="button">Collection</button>

                        <p className="mb-0 d-flex align-items-center text-nowrap">{searchCollectionData && ` ${searchCollectionData}`}
                            {searchCollectionData && <button className="btn btn-primary ms-2" onClick={() => setSearchCollectionData('')}>X</button>}
                        </p>
                    </p>

                    <hr></hr>
                    <div>
                        <button className="btn btn-primary ms-2"
                            onClick={submitSearchData}
                        >Search
                        </button>
                    </div>
                </ModalBody>
            </>
            }
            {nextPage === 1 && <>
                <div className="d-flex justify-content-between  mt-2 px-3 ">
              <h5>SELECT OPTION</h5>
              <span onClick={()=>setNextPage(0)}>
                <i class="bx bx-arrow-back"></i>
              </span> 
            </div>
                <ModalBody>
                    <div className="d-flex mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={handleInputBrandChange}
                        />
                        {/* <button className="btn btn-primary ms-2"
                            onClick={submitSearchData}
                        >Search
                    </button> */}
                    </div>
                    <ul class="list-group list-group-flush">
                        {brandData?.length === 0 && !isLoading && <div className="text-center">No Data Found</div>}
                        {isLoading ? <div className="text-center"><Spinner /></div> : brandData?.map((data) => <li class="list-group-item d-flex justify-content-between"
                            onClick={() => brandSubmitHandler(data?.Code)}
                        >
                            {data?.Name}
                        </li>)}
                    </ul>
                </ModalBody>
            </>
            }
            {nextPage === 2 && <>
            <div className="d-flex justify-content-between  mt-2 px-3 ">
              <h5>SELECT OPTION</h5>
              <span onClick={()=>setNextPage(0)}>
                <i class="bx bx-arrow-back"></i>
              </span> 
            </div>
            {/* <hr></hr> */}
                {/* <ModalHeader className="d-flex justify-content-between">
                    <div>
                    SELECT OPTION
                    </div>
                    
                </ModalHeader> */}
                <ModalBody>
                    <div className="d-flex mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={handleInputCollectionChange}
                        />
                        {/* <button className="btn btn-primary ms-2"
                            onClick={submitSearchData}
                        >Search
                    </button> */}
                    </div>
                    <ul class="list-group list-group-flush">
                        {collectionData?.length === 0 && !isLoading && <div className="text-center">No Data Found</div>}
                        {isLoading ? <div className="text-center"><Spinner /></div> : collectionData?.map((data) => <li class="list-group-item d-flex justify-content-between"
                            onClick={() => collectionSubmitHandler(data?.Code)}
                        >
                            {data?.Name}
                        </li>)}
                    </ul>
                </ModalBody>
            </>
            }
        </Modal>
    )
}
export default SearchStockModal;