const PageLoader = () => {
    return (
        <>
            <div className='pageloader'>
                <div className="spinner-border z-1 text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </>
    )
}

export default PageLoader;