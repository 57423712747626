import { Modal, ModalBody, ModalHeader } from "reactstrap"

const SelectQuantityModal = ({ openModal, toggle, setQuantityNo, quantityNo, mainSetCallApi }) => {

    const toggleHandler = (e) => {
        e.preventDefault();
        if (quantityNo) {
            toggle();
            mainSetCallApi(true);
        }
    }

    return (
        <Modal isOpen={openModal} centered>
            <ModalHeader>
                <h4>Select Quantity</h4>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={toggleHandler}>
                    <input
                        type="number"
                        className="form-control mb-3"
                        step="any"
                        min={"0"}
                        onChange={(e) => setQuantityNo(e.target.value)}
                    />
                    {quantityNo && <button type="submit" className="btn btn-primary">ok</button>}
                </form>
            </ModalBody>
        </Modal>
    )
}

export default SelectQuantityModal;