import { Modal, ModalBody, ModalHeader } from "reactstrap";
import QrReader from 'react-qr-scanner';

const QrModal=({modalToggle,modalOpen,handleError,handleScan,result,videoInputRef,scannedText})=>{
 
   return(
    <Modal isOpen={modalOpen} toggle={modalToggle} centered>
      <ModalHeader toggle={modalToggle}>
                  Scan Here
              {/*<button className="btn">X</button>*/}
       </ModalHeader>
       <div className="flex-column align-items-center justify-content-center my-2 px-2">  
       <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%',height:"100%" }}
      />       
       <p>
        <a href={result} target="blank">{result}</a>
        </p>
        </div>
       {/* <video
         ref={videoInputRef}
         style={{ width: '100%' }}
         onLoadedMetadata={handleScan}
         autoPlay
         playsInline
      />
      <p>{scannedText}</p> */}
      
    </Modal>
   )
}

export default QrModal;