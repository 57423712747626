import { Modal, ModalHeader, Spinner } from "reactstrap";
import { GetDataWithToken } from "../../ApiHelper/ApiHelper";

const CheckoutOptionsModal = ({ modalToggle, isOpen, optionType,
  setCallApi1, setCallApi2, setCallApi3,
  transportList, shipmentList, addressList,
  isLoading, setShipmentName, setTransportName,
  setAddressName, setTransportCode, setShipmentCode, setAddressCode, setSearchText }) => {

  const submitHandler = (code, name) => {
    if (optionType === "transport") {
      setTransportName(name);
      setTransportCode(code);
      modalToggle();
    } else if (optionType === "shipment") {
      setShipmentName(name);
      setShipmentCode(code);
      modalToggle();
    } else if (optionType === "address") {
      setAddressName(name);
      setAddressCode({ name: name, code: code });
      modalToggle();
    }
  }

  const changeHandler = (e) => {
    //  console.log(e.target.value);
    setSearchText(e.target.value);
    if (optionType == "transport") {
      setCallApi1(true);
    } else if (optionType === "shipment") {
      setCallApi2(true);
    } else if (optionType === "address") {
      setCallApi3(true);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={modalToggle} centered>
      <ModalHeader toggle={modalToggle}>
        Options
        {/* <button className="btn">X</button>      */}
      </ModalHeader>
      <div className="d-flex my-2 px-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          onChange={changeHandler}
        />

      </div>
      {/* <input onChange={changeHandler} /> */}
      {optionType === "transport" && <ul class="list-group list-group-flush">
        {transportList.length === 0 &&
          !isLoading &&
          <div className="text-center">No data data found</div>}
        {isLoading ? <div className="text-center" ><Spinner /></div> : transportList?.map((data) => <li class="list-group-item d-flex justify-content-between" onClick={() => submitHandler(data?.Code, data?.Name)}>{data?.Name}</li>)}
      </ul>}
      {optionType === "shipment" && <ul class="list-group list-group-flush">
        {shipmentList.length === 0 &&
          !isLoading &&
          <div className="text-center">No data data found</div>}
        {isLoading ? <div className="text-center" ><Spinner /></div> : shipmentList?.map((data) => <li class="list-group-item d-flex justify-content-between" onClick={() => submitHandler(data?.Code, data?.Name)}>{data?.Name}</li>)}
      </ul>}
      {optionType === "address" && <ul class="list-group list-group-flush">
        {addressList.length === 0 &&
          !isLoading &&
          <div className="text-center">No data data found</div>}
        {isLoading ? <div className="text-center" ><Spinner /></div> : addressList?.map((data) => <li class="list-group-item d-flex justify-content-between" onClick={() => submitHandler(data?.Code, data?.Name)}>{data?.Name}</li>)}
      </ul>}
    </Modal>
  )
}
export default CheckoutOptionsModal;