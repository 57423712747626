import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(true);
    const toggleSidebar = () => setShowSidebar(!showSidebar);
    return (
        <>
            <Sidebar showSidebar={showSidebar} />
            <div class="main-content d-flex flex-column py-2 mb-2">
                <Header toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
                {children}
            </div>
        </>
    )
}
export default Layout;