import { Link, useNavigate } from "react-router-dom";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import { useDispatch, useSelector } from "react-redux";
import { cartData } from "../store/actions/UserActions";
import { useState } from "react";
const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartDataArray = useSelector(state => state.user.cartData);
    const tableArray = ["Code", "name", "DP", "Qty", "UOM", "Subtotal", "Action"];
    console.log("cartt arrayy..", cartDataArray);
    const submitHandler = () => {
        navigate("/checkout", { state: { data: cartDataArray } })
    }

    const removeCartElement = (element) => {
        const newCartData = cartDataArray.filter((data, index) => index !== element);
        // console.log(newCartData);
        dispatch(cartData(newCartData));
    }

    return <>
        <Layout>
            {/* <Heading
                name='Shopping Cart'
                // breadCrumb1="cart"
            // setSearchData={setSearchData}
            // mainSetCallApi={setCallApi}
            // modalToggle={modalToggle}
            // setMainData={setInvoiceData}
            /> */}
            <div className="card">
                <div className="table-responsive">

                    <div className="card">
                        {cartDataArray === undefined || cartDataArray?.length === 0 ? (
                            <h4
                                className="text-center"
                            >
                                No items in Shopping Cart
                            </h4>
                        ) : <table
                            className="table card-table display mb-4 shadow-hover table-responsive-lg table-hover"
                            style={{ minWidth: "150px" }}
                        >
                            <thead>
                                <tr>
                                    {tableArray && tableArray?.map((item) => <th>{item}</th>)}
                                </tr>
                            </thead>

                            <tbody>
                                {cartDataArray?.map((data, index) =>
                                    <tr>
                                        <td>{data?.Code}</td>
                                        <td>{data?.Name}</td>
                                        {<td>{data?.DP}</td>}
                                        <td>{data?.Qty}</td>
                                        <td>{data?.UOM}</td>
                                        <td>{data?.totalPrice && parseFloat(data?.totalPrice).toFixed(2)}</td>
                                        <td>
                                            <button className="btn btn-primary" onClick={() => removeCartElement(index)}><i className="bx bxs-x-circle"></i></button>
                                            {/* <PrimaryButton name='view' clickEvent={() => navigationFunction(data?.Code ? data?.Code : data?.No)} /> */}

                                        </td>
                                    </tr>)}
                            </tbody>

                        </table>}
                        {/* {cartDataArray === undefined || cartDataArray?.length == 0 && <h4
                            className="text-center"
                        >
                            No items in Shopping Cart
                        </h4>} */}
                    </div>

                    {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>} */}
                </div>
                <div className="d-flex  justify-content-center align-item-center mb-2">

                    <Link
                        to="/search-stock"
                        className="btn btn-primary">Continue Shopping</Link>


                    <button className="btn btn-primary mx-2" onClick={submitHandler}>Checkout</button>


                    <button className="btn btn-primary" onClick={() => dispatch(cartData([]))}> clear cart</button>


                </div>
            </div>


            {/* {invoiceData?.length > 0 && <div ref={myRef} id="scroll"></div>} */}
        </Layout>
        {/* <OrderModal
            openModal={openModal}
            modalToggle={modalToggle}
            setCustomerCode={setCustomerCode}
            customerCode={customerCode}
            mainCallApi={setCallApi}
            setDate={setDate}
            date={date}
            setDeliveryName={setDeliveryName}
            deliveryName={deliveryName}
            setIsLoading={setIsLoading}
            setMainData={setInvoiceData}
            setCurrentPage={setCurrentPage}
        /> */}
    </>
}
export default Cart;