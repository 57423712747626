import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import TableComponent from "../Components/Layouts/TableComponent";
import { GetDataWithToken } from "../ApiHelper/ApiHelper";
import OrderModal from "../Components/Modals/OrderModal";
import { toast } from "react-toastify";
import PageLoader from "../Components/Common/PageLoader";
const Orders = () => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [tabOpen, setTabOpen] = useState("1");
  const [callApi, setCallApi] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, settotalPage] = useState(10);
  const [statusCode, setStatusCode] = useState("CONFIRMED");
  const [searchData, setSearchData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [customerCode, setCustomerCode] = useState("");
  const [deliveryName, setDeliveryName] = useState("");
  const [date, setDate] = useState({
    fromDate: null,
    toDate: null,
  });
  const { ref: myRef, inView: visibleElement } = useInView();

  const modalToggle = () => setOpenModal(!openModal);

  const setTabValue = (value) => {
    setTabOpen(value);
    value === "2" ? setStatusCode("NOT_CONFIRMED") : setStatusCode("CONFIRMED");
    setCallApi(true);
    setOrderData([]);
    setIsLoading(true);
    setCurrentPage(1);
  };

  const tableHeading = ["Code", "Date", "Name",
    //  "Action"
  ];

  const filters = [];

  if (searchData) {
    filters.push(`SearchText:${searchData}`);
  }

  if (customerCode) {
    filters.push(`CustomerCode:${customerCode}`);
  }
  if (deliveryName) {
    filters.push(`DeliveryName:${deliveryName}`);
  }
  if (statusCode) {
    filters.push(`StatusCode:${statusCode}`);
  }

  if (date?.fromDate !== null && date?.fromDate !== undefined) {
    filters.push(`FromDate:${date?.fromDate}`);
  }

  if (date?.toDate !== null && date?.toDate !== undefined) {
    filters.push(`ToDate:${date.toDate}`);
  }

  const queryString =
    filters.length > 0
      ? `so/list?pageNo=${currentPage}&pageSize=10&filters=${filters.join(";")}`
      : `so/list?pageNo=${currentPage}&pageSize=10`;

  useEffect(() => {
    if (visibleElement || callApi) {
      setIsLoading(true);
    }
    if (visibleElement || callApi) {
      GetDataWithToken(
        queryString
        // %3BCustomerCode%3AFFG-C-13722%3BStatusCode%3ACONFIRMED%3BFromDate%3A2023-08-01T18%3A30%3A00.000Z%3BToDate%3A2023-08-23T18%3A30%3A00.000Z%3BDeliveryName%3A%2520ANJANA%2520SHARMA
        // `so/list?pageNo=${currentPage}&pageSize=10&filters=SearchText:${searchData}%3BStatusCode%3A${statusCode};FromDate:${date?.fromDate ? date?.fromDate : ''};ToDate:${date?.toDate ? date?.toDate : ''};DeliveryName:${deliveryName};CustomerCode:${customerCode}`
      ).then((response) => {
        if (response.status === 500) {
          setPageLoading(false);
          toast.error(response.data);
          setIsLoading(false);
          setCallApi(false);
        } else {
          // console.log(response);
          setPageLoading(false);
          setIsLoading(false);
          setOrderData((prevState) => [...prevState, ...response]);
          setCallApi(false);
          setCurrentPage((prevState) => prevState + 1);
        }
      });
    }
  }, [callApi, visibleElement]);

  return (
    <>
      <Layout>
        {/* name,modalToggle,setSearchData,mainSetCallApi,DownloadReportHandler,breadCrumb1,breadCrumb2 */}
        <Heading
          name="Orders"
          // breadCrumb1="orders"
          searchData={searchData}
          setSearchData={setSearchData}
          mainSetCallApi={setCallApi}
          modalToggle={modalToggle}
          setMainData={setOrderData}
          setCurrentPage={setCurrentPage}
          setPageLoading={setPageLoading}
        />
        <div className="card">
          {!isLoading && (
            <Nav tabs pills fill className="border-bottom-0 mb-2 px-2">
              <NavItem>
                <NavLink
                  style={{ fontWeight: "600" }}
                  className={tabOpen === "1" ? "active" : ""}
                  onClick={() => setTabValue("1")}
                >
                  CONFIRMED
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontWeight: "600" }}
                  className={tabOpen === "2" ? "active" : ""}
                  onClick={() => setTabValue("2")}
                >
                  NOT-CONFIRMED
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <TabContent activeTab={tabOpen}>
            <TabPane tabId="1">
              <div>
                <TableComponent
                  isLoading={isLoading}
                  data={orderData}
                  navigation="/order-detail"
                  tableHeading={tableHeading}
                />
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="table-responsive">
                <TableComponent
                  isLoading={isLoading}
                  data={orderData}
                  navigation="/order-detail"
                  tableHeading={tableHeading}
                />
              </div>
            </TabPane>
          </TabContent>
          {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
        </div>
        {orderData?.length >= 9 && !isLoading && (
          <div ref={myRef} id="scroll"></div>
        )}
      </Layout>
      {/* <OrderModal
        modalToggle={modalToggle}
        openModal={openModal}
        mainCallApi={callApi}
        date={date}
        setDate={setDate}
        customerCode={customerCode}
        setCustomerCode={setCustomerCode}
        
      />   */}
      <OrderModal
        openModal={openModal}
        modalToggle={modalToggle}
        setCustomerCode={setCustomerCode}
        customerCode={customerCode}
        mainCallApi={setCallApi}
        setDate={setDate}
        date={date}
        setDeliveryName={setDeliveryName}
        deliveryName={deliveryName}
        setIsLoading={setIsLoading}
        setMainData={setOrderData}
        setCurrentPage={setCurrentPage}
      />
      {pageLoading && <PageLoader />}
    </>
  );
};
export default Orders;
