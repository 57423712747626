import QrModal from "../Modals/QrModal";

const Heading = ({
  name,
  modalToggle,
  setSearchData,
  mainSetCallApi,
  DownloadReportHandler,
  breadCrumb1,
  breadCrumb2,
  setMainData,
  createQuotation,
  setCurrentPage,
  qrModalToggle,
  searchData,
  setPageLoading,

}) => {
  const submitHandler = (e) => {
    e.preventDefault();
    mainSetCallApi(true);
    setMainData([]);
    setCurrentPage(1);
    setPageLoading(true);
  };

  return (
    <>
      <div class="breadcrumb-area px-2 px-md-4 py-2 align-items-center justify-content-between">
        <div className="d-flex">
          {name && <h1>{name}</h1>}

          {/* <ol class="breadcrumb">
          {breadCrumb1 && <li class="">{breadCrumb1} / </li>}
          {breadCrumb2 && (
            <li style={{ paddingLeft: "5px" }}> {breadCrumb2}</li>
          )}
        </ol> */}
        </div>

        <div className="d-flex justify-content-md-end">
          {setSearchData && (
            <ol className="breadcrumb">
              <li class="item">
                <div class="input-group">
                  <form className="d-flex mx-2" onSubmit={submitHandler}>
                    <input
                      type="text"
                      class="form-control"
                      style={{ borderRadius: "5px" }}
                      placeholder="Search here..."
                      value={searchData}
                      onChange={(e) => setSearchData(e.target.value)}
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary ml-2"
                        type="submit"
                        id="button-addon2"
                      // onClick={submitHandler}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </li>
            </ol>
          )}
          {modalToggle && (
            <ol className="breadcrumb mx-1" onClick={modalToggle}>
              <button className="btn btn-primary">
                <i className="bx bx-filter-alt"></i>
              </button>
            </ol>
          )}
          {DownloadReportHandler && (
            <ol
              className="breadcrumb"
              onClick={DownloadReportHandler}
            >
              <button className="btn btn-primary">
                {breadCrumb1 == "Ledger" ? <i className=" bx bxs-file-export"></i> :
                  "Download"}
                {/* download */}

              </button>
            </ol>
          )}
          {createQuotation && (
            <ol className="breadcrumb" onClick={createQuotation}>
              <button className="btn btn-primary">
                <i className=" bx bx-plus"></i>
              </button>
            </ol>
          )}

          {qrModalToggle && (window.screen.width < 720) &&
            <ol className="breadcrumb"
              onClick={qrModalToggle}
            >
              <button className="btn btn-primary">
                qr
              </button>
            </ol>}
        </div>
      </div>
    </>
  );
};
export default Heading;
