import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GetDataWithToken } from "../../ApiHelper/ApiHelper";
import Heading from "../Layouts/Heading";
import { useInView } from "react-intersection-observer";
import { event } from "jquery";
import { useNavigate } from "react-router-dom";
import TableComponent from "../Layouts/TableComponent";
import PrimaryButton from "../Common/PrimaryButton";

const AddQuotationModal = ({ openModal, modalToggle,
    mainCallApi,
    setIsLoading, setMainData, setCurrentPage }) => {
    const navigate = useNavigate();
    const [nextPage, setNextPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [orderDataList, setOrderDataList] = useState([]);
    const [orderedItemList, setOrderedItemList] = useState([]);
    const [callApi, setCallApi] = useState(false);
    const [callApi2, setCallApi2] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({});
    const [currentCustomerPage, setCurrentCustomerPage] = useState(1);
    const { ref: myRef, inView: visibleElement } = useInView();

    const quantityChangeHandler = (event, selectedIndex, data) => {
        let updatedData = {
            ...data,
            Qty: event.target.value
        };

        const updatedItemList = [...orderedItemList];
        updatedItemList[selectedIndex] = updatedData;

        // Update the state with the new array
        setOrderedItemList(updatedItemList);

        // quantityChangeArray = orderedItemList.filter((data, index) => selectedIndex !== index);
        // quantityChangeArray.push(submitData);
        // console.log(quantityChangeArray);
        // setOrderedItemList([newOrderedListitem]);
    }

    const handleInputChange = (e) => {
        setCustomerList([]);
        setCurrentCustomerPage(1);
        setSearchValue(e.target.value);
        setCallApi(true);
    }

    const toggle = () => {
        modalToggle();
        setNextPage(0);
    }

    const orderedItemsHandler = (data) => {
        // console.log("dataa........", customerDetails);
        GetDataWithToken(`api/items/price?code=${data?.Code}&customerCode=${customerDetails?.Code}`)
            .then((response) => {
                let submitData = {
                    PriceGroupCode: response.PriceGroupCode,
                    DiscountPerc: 0,
                    ItemCode: data?.Code,
                    Currency: response?.Currency,
                    Price: response?.Price,
                    ItemName: data?.Name,
                    Uom: data?.Uom,
                    Qty: 1
                }
                setOrderedItemList((prevData) => [...prevData, submitData]);
                // console.log(orderedItemList);
                setNextPage(0);
            });

        // console.log(data);

    }
    // const customerListSubmitHandler = (code,name) => {
    //     setCustomerName(name);
    //     setCallApi2(true);
    // }
    // const dateSubmitHandler = () => {
    //     // mainCallApi(true);
    //     // toggle(); 
    //     setNextPage(0);
    // }

    const deleteHandler = (selectedIndex) => {
        // console.log('deleteHandler', selectedIndex);
        const newOrderedListitem = orderedItemList.filter((data, index) => selectedIndex !== index);
        setOrderedItemList(newOrderedListitem)
    }
    const submitSearchData = () => {
        // setOrderedItemList(quantityChangeArray);
        // console.log(orderedItemList);
        // setMainData([]);
        // setIsLoading(true);
        // toggle();
        // setCurrentPage(1);
        // mainCallApi(true);
        navigate("/quotation-checkout", {
            state: {
                data: {
                    customer: customerDetails,
                    orderedItemList: orderedItemList
                }
            }
        })
    }

    const customerSubmitHandler = (data) => {
        setCustomerDetails(data)
        setNextPage(0);
        setCallApi(true);
        // mainCallApi(true);
        // toggle();
    }

    const addTableHeading = ["code,", "name", "action"];

    useEffect(() => {
        if (callApi || visibleElement) {
            GetDataWithToken(`api/customers/all?filters=SearchText:${searchValue}&pageNo=${currentCustomerPage}&pageSize=50`).then((response) => {
                setCustomerList((prevData) => [...prevData, ...response]);
                setCurrentCustomerPage((prevPage) => prevPage + 1);
                setCallApi(false);
            })
        }
        if (callApi2) {
            GetDataWithToken(`sq/dropdown?pageNo=1&pageSize=10`).then((response) => {
                setOrderDataList(response);
                setCallApi2(false);
            })
        }

    }, [callApi, visibleElement, callApi2])


    return (
        <Modal isOpen={openModal} toggle={toggle}  className="Qutation-modal" centered size="lg">
             {/* <ModalHeader toggle={toggle}>
                    <h4>Create Quotation</h4>
                    <div className="col-6 d-flex justify-content-end">
                    {customerDetails.Name && (
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                setNextPage(2);
                                setCallApi2(true);
                            }}
                        >
                            <span className="icon">
                                <i class='bx bx-plus'></i>
                            </span>
                        </button>
                    )}
                </div>
                    </ModalHeader>    */}
            <div className="d-flex justify-content-between py-2 ">
            <h4 style={{width:"92%"}}>Quotation Modal</h4>
            <>
            {customerDetails.Name && (<button className="btn btn-primary"
               onClick={() => {
                setNextPage(2);
                setCallApi2(true);
            }}
            >
                 <span className="icon">
                                <i class='bx bx-plus'></i>
                            </span></button>)}
                            <button type="button" class="btn-close mx-2" onClick={toggle}></button>
            {/* <button className="btn" onClick={toggle}>x</button> */}
            </> 
            </div>   
            
            {nextPage == 0 && <ModalBody>

                <p className="ms-2 d-flex align-items-center justify-content-between" >
                    <button
                        className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                        onClick={() => { setNextPage(1); setCallApi(true) }}
                        type="button">Customer</button>
                    <p className="mb-0 d-flex align-items-center text-nowrap">{customerDetails.Name && ` ${customerDetails.Name}`}
                        {customerDetails.Name && <button className="btn btn-primary ms-2"
                            onClick={() => { setCustomerDetails({}); setOrderedItemList([]) }}
                        >X</button>}
                    </p>
                </p>

                {/* <span onClick={() => { setNextPage(1); setCallApi(true) }}><strong>Customer</strong></span>
                {customerDetails.Name && <span>{customerDetails.Name}
                    <button className="btn btn-primary"
                    onClick={() => { setCustomerDetails({}); setOrderedItemList([]) }} >X</button></span>} */}
                <div>
                </div>
                <div>
                    <hr />
                    {/* <TableComponent tableHeading={addTableHeading} data={orderedItemList} /> */}
                    {orderedItemList?.length > 0 &&
                        <div className="card">
                            <div className="table-responsive">
                                <table
                                    className="table card-table display mb-4 shadow-hover table-responsive-lg table-hover"
                                >
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>DP</th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderedItemList?.map((data, index) => <tr>
                                            <td>{data?.ItemName}</td>
                                            <td>{data?.ItemCode}</td>
                                            <td>{data?.Price}</td>
                                            <td><input type="number" placeholder="enter quantity..." defaultValue={data?.Quantity} min={0} className="form-control" onChange={(event) => quantityChangeHandler(event, index, data)} /></td>
                                            <td>
                                                {/* <PrimaryButton clickEvent={() => deleteHandler(index)} name="delete" /> */}
                                                <button className="btn btn-primary" onClick={() => deleteHandler(index)}><i className=" bx bx-trash-alt"></i></button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div></div>}
                    {customerDetails && <button className="btn btn-primary" onClick={submitSearchData} >Submit</button>}
                </div>
            </ModalBody>}
            { nextPage == 1 && <ModalBody>
                    <div className="mb-2">
                        <input type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={handleInputChange}
                        />
                    </div>
                    <ul class="list-group list-group-flush">
                        {customerList?.map((data) =>
                            <li class="list-group-item d-flex justify-content-between" onClick={() => customerSubmitHandler(data)}>{data?.Name}</li>)}
                    </ul>
                    {<div ref={myRef} id="scroll"></div>}
                </ModalBody>
            }
            {
                nextPage == 2 && <ModalBody>
                    <div className="mb-2">
                        <input type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={handleInputChange}
                        />
                    </div>
                    <ul class="list-group list-group-flush">
                        {orderDataList?.map((data) => <li class="list-group-item " onClick={() => orderedItemsHandler(data)}><div ><strong className="mx-2" >Code:</strong>{data?.Code}</div><p><strong className="mx-2">Name:</strong>{data?.Name}</p></li>)}
                    </ul>
                </ModalBody>
            }
        </Modal>
    )
}

export default AddQuotationModal;