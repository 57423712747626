import { useEffect, useState } from "react";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import { GetDataWithToken } from "../ApiHelper/ApiHelper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import PageLoader from "../Components/Common/PageLoader";

const AlternateItems = () => {
    const [pageLoading, setPageLoading] = useState(false);
    const [alternateData, setAlternateData] = useState([]);
    const navigate = useNavigate();
    const [callApi, setCallApi] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchData, setSearchData] = useState();
    const { ref: myRef, inView: visibleElement } = useInView();
    const location = useLocation();
    //   console.log("ooollooo",location);


    const navigationFunction = (code) => {
        navigate("/search-stock-detail", {
            state: {
                id: code,
            },
        });
    };

    useEffect(() => {
        if (visibleElement || callApi) {
            setIsLoading(true);
            GetDataWithToken(`api/items/alternate-items?productLook=${location.state.productLook}&categoryCode=${location.state.categoryCode}&colorCode=${location?.state?.colorCode}&pageNo=${currentPage}&pageSize=10`)
                .then((response) => {
                    if (response.status === 500) {
                        toast.error(response.data);
                        setIsLoading(false);
                        setPageLoading(false);
                    } else {
                        setPageLoading(false);
                        setAlternateData(prevResponse => [...prevResponse, ...response]);
                        setCurrentPage((prevPage) => prevPage + 1);
                        setCallApi(false);
                        setIsLoading(false);
                    }
                })
        }
    }, [visibleElement, callApi])

    return (
        <>
            <Layout>
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Heading name="Alternate items"
                                    setSearchData={setSearchData}

                                    searchData={searchData}
                                    mainSetCallApi={setCallApi}
                                    setMainData={setAlternateData}
                                    setCurrentPage={setCurrentPage}
                                    setPageLoading={setPageLoading}
                                />
                                {/* <div className="card-header">
                          <div className="col-lg-4">
                             <h4 className="card-title">Alternate items</h4>
                        </div>
                          <div className="col-lg-4 d-flex">
                              <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={(e)=>setSearchData(e.target.value)}
                                />
                            <button className="btn btn-primary ms-2"
                                onClick={submitSearchData}
                            >Search
                                 </button>
                        </div>
                        <div className="d-flex">
                                <button className="btn btn-primary ms-2" onClick={modalToggle}>Filter
                               </button>
                       </div> 
                         <div className="col-lg-2 d-flex">
                                <button className="btn btn-primary ms-2" onClick={DownloadReportHandler}>Download
                               </button>
                        </div>
                    </div>      */}
                                <div className="card">
                                    <div className="table-responsive">
                                        <table
                                            className="table card-table display mb-4 shadow-hover table-responsive-lg table-hover"
                                            style={{ minWidth: "150px" }}
                                        >
                                            <thead>
                                                <tr >

                                                    <th>Code</th>
                                                    <th>Name</th>
                                                    <th>Qty inv</th>
                                                    <th>Pieces</th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {alternateData?.map((data) =>
                                                    <tr
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => navigationFunction(
                                                            data?.Code
                                                        )}>
                                                        <td>{data?.Code}</td>
                                                        <td>{data?.Name}</td>
                                                        <td>{data?.QtyInv}</td>
                                                        <td>{data?.BatchCount}</td>
                                                        <td>
                                                            {/* <Link className="btn btn-primary"
                                       to="/search-stock-detail"
                                       state={{id:data?.Code}}
                                        >
                                        View
                                    </Link> */}
                                                        </td>
                                                    </tr>)}

                                                {isLoading && <h4 style={{
                                                    position: "absolute",
                                                    left: "40%",
                                                    padding: "10px"
                                                }}>Loading...</h4>}
                                            </tbody>
                                        </table>
                                        {alternateData?.length >= 9 && !isLoading && <div ref={myRef} id="scroll"></div>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            {pageLoading && <PageLoader />}
        </>
    )
}
export default AlternateItems;