import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const Sidebar = ({ showSidebar }) => {
  const roleType = useSelector((state) => state?.user?.userDetails?.Role);
  const cartItem = useSelector((state) => state?.user?.cartData);
  // const[width,setWidth]=useState("");
  // console.log("rolllleeee....", roleType);
  // const doc=window?.document?.getElementsByTagName("body")[0]; 
  // const w=doc.innerWidth||doc.clientWidth||doc.clientWidth;
  // setWidth(w); 
  // console.log(width);
  // if(w<1000){
  //   // console.log("width printed");
  //   const elements=window?.document.getElementsByClassName("sidemenu-area");
  //   if (elements.length > 0) {
  //     elements[0].classList.add("toggle-sidemenu-area");
  // }  // doc1.classList?.add("toggle-sidemenu-area"); 
  // }
  //  if(w>1000){
  //   const elements=window?.document.getElementsByClassName("sidemenu-area");
  //   if (elements.length > 0) {
  //     elements[0].classList.remove("toggle-sidemenu-area");
  //   }
  // }
  //   useEffect(() => {

  // }, [w])

  //  useEffect=(()=>{
  //    if(width<1000){
  //      const doc1=window?.document.getElementsByClassName("sidemenu-area");
  //      doc1.classList?.add("toggle-sidemenu-area"); 
  //    }
  // },[w])

  return (
    <div class={showSidebar ? "sidemenu-area" : "sidemenu-area active-sidemenu-area"}>
      <div class="sidemenu-header" style={{ paddingLeft: "60px" }}>
        <img src="img/loginLogo.png" width={100} alt="image" />
      </div>
      <div class="sidemenu-body">
        <ul
          class="sidemenu-nav metisMenu h-100"
          id="sidemenu-nav"
          data-simplebar
        >
          {/* <li class="nav-item-title">
                        Main
                    </li> */}
          <li>

          </li>
          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-item"
                : isActive
                  ? "nav-item mm-active"
                  : "nav-item"
            }
            to="/home"
          >
            <div className="nav-link">
              <span class="icon">
                <i class="bx bx-home-circle"></i>
              </span>
              <span class="menu-title">Dashboard</span>
            </div>
          </NavLink>

          {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ||
            roleType === "GUEST" ? (
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
              // className="nav-item"
              // activeClassName="mm-active"

              to="/search-stock"
            >
              <div className="nav-link">
                <span class="icon">
                  <i class=" bx bx-search-alt"></i>
                </span>
                <span class="menu-title">Search Stock</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}



          {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ? (
            <NavLink
              to="/orders"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bx-menu-alt-left"></i>
                </span>
                <span class="menu-title">Orders</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {roleType === "ADMIN" || roleType === "SALES_PERSON" ? (
            <NavLink
              to="/quotation"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bxs-notepad"></i>
                </span>
                <span class="menu-title">Quotation</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {roleType === "DEALER" && (
            <NavLink
              to="/cart"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <div className="d-flex justify-content-between ">
                  <div>
                    {" "}
                    <span class="icon">
                      <i class="bx bxs-cart"></i>
                    </span>
                    <span class="menu-title">Cart</span>{" "}
                  </div>

                  <span className="badge badge-success  p-2">
                    {cartItem?.length > 0 && cartItem?.length}
                  </span>
                </div>
              </div>
            </NavLink>
          )}
          {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ? (
            <NavLink
              to="/invoices"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bxs-dashboard"></i>
                </span>
                <span class="menu-title">Invoices</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ? (
            <NavLink
              to="/customer-ledger"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bx-book-reader"></i>
                </span>
                <span class="menu-title">Customer Ledger</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
          {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ||
            roleType === "GUEST" ? (
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
              // className="nav-item"
              // activeClassName="mm-active"

              to="/profile"
            >
              <div className="nav-link">
                <span class="icon">
                  <i class=" bx bx-user"></i>
                </span>
                <span class="menu-title">My Profile</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
