import { useState } from "react";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PostData, serverUrl } from "../../ApiHelper/ApiHelper";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  setUserLoggedInDetails,
  setUsertype,
} from "../../store/actions/UserActions";
import PageLoader from "../../Components/Common/PageLoader";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log("errorrs", errors);
    setPageLoading(true);
    let loginData = {
      password: data.password,
      userName: data.email,
    };
    let dateRange = {
      FromDate: null,
      PageNo: 1,
      PageSize: 10,
      ToDate: null,
      dateRange: null,
      selectedDay: "All",
    };
    PostData("user/login", loginData).then((response) => {
      if (response?.data?.Message) {
        toast(response?.data?.Message, {
          position: "top-right",
        });
        setPageLoading(false);
      } else {
        setPageLoading(false);
        dispatch(setUsertype(data.email));
        Cookies.set("F&FuserName", data.email);
        setToken(response);
        var decoded = jwt_decode(response);
        dispatch(setUserLoggedInDetails(decoded));
        Cookies.set("F&ftToken", response);
        navigate("/home");
      }
    });
  };

  return (
    <>
      <div className="login-area authincation h-100">
        <div className="container">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="login-form">
                <div className="d-flex justify-content-center">
                  <div
                    className="logo"
                    style={{
                      padding: "10px",
                      paddingBottom: "10px",
                      width: "200px",
                    }}
                  >
                    <a href="#">
                      <img
                        src="img/loginLogo.png"
                        alt="image"
                      //   style={{ width: "250px", height: "80px" }}
                      />
                    </a>
                  </div>
                </div>

                <h2
                  style={{
                    backgroundImage:
                      "linear-gradient(344deg, rgba(43,37,38,1) 28%, rgba(112,100,101,1) 70%)",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color: "transparent",
                    fontWeight: "600",
                  }}
                >
                  Trade Login
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      placeholder="Email"
                      {...register("email", {
                        required: "Please enter username",
                        maxLength: 80,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-user"></i>
                    </span>
                  </div>

                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      placeholder="Password"
                      {...register("password", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-lock"></i>
                    </span>
                  </div>

                  {/* <div class="form-group">
                                <div class="remember-forgot">
                                    <label class="checkbox-box">Remember me
                                        <input type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>

                                    <a href="forgot-password.html" class="forgot-password">Forgot password?</a>
                                </div>
                            </div> */}

                  <button type="submit" class="login-btn">
                    Login
                  </button>

                  <p class="mb-0">
                    Don’t have an account? <Link to="/sign-up">Sign Up</Link>
                  </p>
                  <a
                    href={`${serverUrl}privacy_policy.html`}
                    target="_blank"
                  >
                    Privacy policy
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageLoading && <PageLoader />}
    </>

  );
};
export default Login;
