import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetDataWithToken, PostData } from "../../ApiHelper/ApiHelper";
import PageLoader from "../../Components/Common/PageLoader";

const Signup = () => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [stateArray, setStateArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [callApi, setCallApi] = useState(true);
  const [selectedState, setSelectedState] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    setPageLoading(true);
    const signupData = {
      City: data?.city,
      ConfirmPassword: data?.confirmpassword,
      Email: data?.email,
      MobileNo: data?.mobile,
      Name: data?.name,
      Password: data?.password,
      UserCompanyName: data?.company,
    };
    PostData(`user/signup`, signupData).then((response) => {
      // console.log(response);
      if (response.status === 500) {
        setPageLoading(false);
        toast(response?.data, {
          position: "top-right",
        });
      } else {
        setPageLoading(false);
        navigate("/");
        Cookies.set("F&ftToken", response);
        toast(response?.data, {
          position: "top-right",
        });
      }
    });
  };

  //state change
  const stateChangeHandler = (e) => {
    // console.log("state change", e.target.value);
    setSelectedState(e.target.value);
    setCallApi(true);
  };

  useEffect(() => {
    GetDataWithToken(`api/address/states?countryID=IND`).then((response) => {
      setStateArray(response);
    });
  }, []);

  useEffect(() => {
    GetDataWithToken(`api/address/cities?stateID=${selectedState}`).then(
      (response) => {
        setCityArray(response);
        setCallApi(false);
      }
    );
  }, [callApi]);

  return (
    <>
      <div class="login-area authincation h-100">
        <div className="container">
          <div class="row justify-content-center h-100 align-items-center">
            <div class="col-12 col-md-8 col-lg-6">
              <div class="login-form">
                <div className="d-flex justify-content-center">
                  <div class="logo"
                    style={{
                      padding: "10px",
                      paddingBottom: "10px",
                      width: "200px",
                    }}
                  >
                    <a href="#">
                      <img
                        src="img/loginLogo.png"
                        alt="image"
                      // style={{ width: "250px" }}
                      />
                    </a>
                  </div>
                </div>
                <h2
                  style={{
                    backgroundImage:
                      "linear-gradient(344deg, rgba(43,37,38,1) 28%, rgba(112,100,101,1) 70%)",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color: "transparent",
                    fontWeight: "600",
                  }}
                >
                  Welcome
                </h2>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      placeholder="Name"
                      {...register("Name", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-user"></i>
                    </span>
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Email"
                      {...register("email", {
                        required: true,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-mail-send"></i>
                    </span>
                  </div>
                  <div class="form-group">
                    <input
                      type="number"
                      maxLength={10}
                      class="form-control"
                      name="mobileNumber"
                      placeholder="Mobile no."
                      {...register("mobile", {
                        required: true,
                        maxLength: 10,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-mobile"></i>
                    </span>
                  </div>

                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      placeholder="Password"
                      {...register("password", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-lock"></i>
                    </span>
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      name="confirmpassword"
                      placeholder="Confirm Password"
                      {...register("confirmpassword", {
                        required: true,
                        maxLength: 10,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-lock"></i>
                    </span>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="company"
                      placeholder="Company"
                      {...register("company", {
                        required: true,
                        maxLength: 10,
                      })}
                    />
                    <span class="label-title">
                      <i class="bx bx-current-location"></i>
                    </span>
                  </div>
                  <div class="form-group">
                    <select
                      class="form-control"
                      name="state"
                      onChange={stateChangeHandler}
                    //  {...register("state", {
                    //     required: true,
                    //   })}
                    >
                      <option value={null} selected>
                        State
                      </option>
                      {stateArray.map((state) => (
                        <option value={state?.Code}>{state?.Name}</option>
                      ))}
                    </select>
                    <span class="label-title">
                      <i class="bx bx-globe"></i>
                    </span>
                  </div>
                  <div class="form-group">
                    <select
                      class="form-control"
                      name="city"
                      {...register("city", {
                        required: true,
                      })}
                    >
                      <option value={null} selected>
                        City
                      </option>
                      {cityArray?.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                    </select>
                    <span class="label-title">
                      <i class="bx bxs-city"></i>
                    </span>
                  </div>
                  {/* <div class="form-group">
                                <div class="remember-forgot">
                                    <label class="checkbox-box">Remember me
                                        <input type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>

                                    <a href="forgot-password.html" class="forgot-password">Forgot password?</a>
                                </div>
                            </div> */}

                  <button type="submit" class="login-btn">
                    Sign up
                  </button>

                  <p class="mb-0">
                    Already have an account? <Link to="/">Login</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageLoading && <PageLoader />}
    </>
  );
};

export default Signup;
