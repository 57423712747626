import { useEffect, useState } from "react";
import Layout from "../Components/Common/Layout"
import { GetDataWithToken, serverUrl } from "../ApiHelper/ApiHelper";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Spinner, Table } from "reactstrap";
import Heading from "../Components/Layouts/Heading";
import Cookies from "js-cookie";
import axios from "axios";

const QuotationDetail = () => {
    const location = useLocation();
    const [detailData, setDetailData] = useState({});
    const [downloadLoading, setDownloadLoading] = useState(false);
    let tokens = Cookies.get("F&ftToken");

    const downloadHandler = () => {
        setDownloadLoading(true);
        GetDataWithToken(`sq/pdf?code=${location?.state?.id}`).then((response) => {
            axios({
                // url: `http://tailoring.fandf.in/api/items/list?pageNo=${currentPage}&filters=${searchData?"SearchText:":""}${searchData?searchData:""};CategoryCode:${categoryCode};CollectionCode:${searchCollectionData};BrandCode:${searchBrandData}&excel=true`,
                url: `${serverUrl}report/download?fileName=${response}&type=pdf`,
                method: "GET",
                headers: {
                    Authorization: "Bearer " + tokens,
                    "Accept-Language": "en",
                },
                responseType: "blob",
                // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.pdf"); //or any other extension
                document.body.appendChild(link);
                setDownloadLoading(false);
                link.click();
                // setLoadingData(false);
            });
        })

    }

    useEffect(() => {
        GetDataWithToken(`sq/details?code=${location?.state?.id}`).then((response) => {
            setDetailData(response);
        })
    }, [])

    return (
        <Layout>
            {downloadLoading ? <>
                <div className="text-center pt-5 mt-5">
                    <Spinner /><p>Downloading Please Wait...</p></div>
            </> :
                <div className="content-body">
                    <div className="container-fluid px-0">
                        <Heading name="Quotation Details"
                            DownloadReportHandler={downloadHandler}
                        // breadCrumb1={"quotation"}
                        // breadCrumb2={"quotation-detail"}
                        />
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-2">
                                    {/* <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-11">
                                            <h4 className="card-title">Quotation details</h4>
                                        </div>
                                        <div className="col-lg-1">
                                            <button className="btn btn-primary" onClick={downloadHandler}>
                                                <i className='bx bxs-folder-minus'></i>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <ul class="list-group mb-lg-0 mb-2">
                                                <li class="list-group-item d-flex justify-content-between ">
                                                    <strong>Quotation No:</strong>
                                                    <span class="mb-0">{detailData?.Code}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <strong>Quotation Date:</strong>
                                                    <span class="mb-0">{moment(detailData?.QuotationDate)?.format('DD/MM/YYYY')}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Sales Person Contact:</strong>
                                                    <span class="mb-0">{detailData?.SalesPersonContact}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Remarks:</strong>
                                                    <span class="mb-0">{detailData?.Remarks}</span>
                                                </li>

                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Delivery Mode:</strong>
                                                    <span class="mb-0">{detailData?.Shipment}</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="col-lg-6">
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <strong>Customer Number:</strong>
                                                    <span class="mb-0">{detailData?.Customer?.Code}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Sales Person Name</strong>
                                                    <span class="mb-0">{detailData?.SalesPersonName}</span>
                                                </li>

                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Sales Person Email</strong>
                                                    <span class="mb-0">{detailData?.SalesPersonEmail?.split(';')?.map((data) => <span style={{ justifyContent: "end", display: "block" }}>{data}</span>)}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Transport:</strong>
                                                    <span class="mb-0">{detailData?.Transport?.Name}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">

                                                    <strong>Delivery Name:</strong>
                                                    <span class="mb-0">{detailData?.DeliveryName}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="col-lg-3 my-2">
                                            <h4 className="card-title">items :</h4>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="mt-2 table  table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            item
                                                        </th>

                                                        <th>
                                                            Quantity
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailData?.Lines?.map((data) =>
                                                        < tr >
                                                            <td>
                                                                {data?.ItemName}
                                                            </td>
                                                            <td>
                                                                {data?.Qty && +Math?.abs(data?.Qty)?.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </Layout>
    )
}
export default QuotationDetail;