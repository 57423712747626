import { useEffect, useState } from "react";
import Layout from "../Components/Common/Layout";
import { useForm } from "react-hook-form";
import { GetDataWithToken, PostDataWithToken } from "../ApiHelper/ApiHelper";
import Heading from "../Components/Layouts/Heading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckoutOptionsModal from "../Components/Modals/CheckoutOptions";
import { cartData } from "../store/actions/UserActions";
import PageLoader from "../Components/Common/PageLoader";

const Checkout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const modalToggle = () => setIsOpen(!isOpen);
    const location = useLocation();
    const [callApi1, setCallApi1] = useState(true);
    const [callApi2, setCallApi2] = useState(true);
    const [callApi3, setCallApi3] = useState(true);
    // console.log("..lelkf..", location);
    const [transportList, setTransportList] = useState([]);
    const [transportName, setTransportName] = useState("");
    const [shipmentList, setShipmentList] = useState([]);
    const [shipmentName, setShipmentName] = useState("");
    const [addressList, setAddressList] = useState([]);
    const [addressName, setAddressName] = useState("");
    const [optionType, setOptionType] = useState("");
    const [transportCode, setTransportCode] = useState("");
    const [shipmentCode, setShipmentCode] = useState("");
    const [addressCode, setAddressCode] = useState({ name: "", code: "" });
    const [searchText, setSearchText] = useState("");
    const [remarks, setRemarks] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // let userData = useSelector((store) => store?.user?.userDetails);

    // console.log("dskav............",userData?.UserName);
    let totalAmount = 0;

    for (let i = 0; i < location.state.data.length; i++) {
        totalAmount += location.state.data[i].totalPrice
    }
    const onSubmit = (data) => {

        setPageLoading(true);

        const submitData = {
            Amount: totalAmount,
            Cart: location.state.data,
            DeliveryAddress:
                { Code: addressCode.code, Name: addressCode.name, Type: null },
            Lines: location.state.data,
            Remarks: remarks,
            Shipment
                :
                { Code: shipmentCode, Name: shipmentName, Type: null },
            Transport
                :
                { Code: transportCode, Name: transportName, Type: null }
        }
        // const da = {
        //     Cart: [
        //       {
        //         Name: "San Marino-Apple Green",
        //         Code: "FF-0042474",
        //         DP: 333,
        //         UOM: "MTRS",
        //         Qty: 1,
        //         ItemCode: "FF-0042474",
        //       },
        //     ],
        //     Amount: 333,
        //     Transport: { Code: "NITCO RO", Name: "NITCO ROADWAYS", Type: null },
        //     Shipment: { Code: "BY AIR", Name: "BY AIR", Type: null },
        //     DeliveryAddress: {
        //       Code: "5637853483",
        //       Name: "A 1 AGRO PIPES-Shop no. 1 Raj Cresent Co-op Housing Society, Eksar Road, Royal complex\nNear Jain Temple, Borivali (W)\nMumbai-400091\nMaharastra-IND",
        //       Type: null,
        //     },
        //     Remarks: "test",
        //     Lines: [
        //       {
        //         Name: "San Marino-Apple Green",
        //         Code: "FF-0042474",
        //         DP: 333,
        //         UOM: "MTRS",
        //         Qty: 1,
        //         ItemCode: "FF-0042474",
        //       },
        //     ],
        //   };

        // console.log("submm-i-t--t-t--",submitData);
        PostDataWithToken(`so/create`, submitData)
            .then((response) => {
                setPageLoading(false);
                toast(response.data, {
                    position: "top-right",
                });
                navigate("/home")
                dispatch(cartData([]));
            })

    }

    const modalOpenHandler = (input) => {
        setOptionType(input);
        modalToggle();
    }

    const filters = [];
    if (searchText) {
        filters.push(`SearchText:${searchText}`);
    }

    const queryString1 = filters?.length > 0 ? `so/transport-options?filters=${filters.join(';')}&pageNo=1&pageSize=50` : `so/transport-options?pageNo=1&pageSize=50`;
    const queryString2 = filters?.length > 0 ? `so/shipment-options?filters=${filters.join(';')}&pageNo=1&pageSize=50` : `so/shipment-options?pageNo=1&pageSize=50`;
    const queryString3 = filters?.length > 0 ? `so/address-options?filters=${filters.join(';')}&pageNo=1&pageSize=50` : `so/address-options?pageNo=1&pageSize=50`;

    useEffect(() => {
        if (callApi1) {
            setIsLoading(true);
            GetDataWithToken(queryString1).then(response => {
                setTransportList(response);
                setCallApi1(false);
                setIsLoading(false);
            })
        }
        if (callApi2) {
            setIsLoading(true);
            GetDataWithToken(queryString2).then(response => {
                setShipmentList(response);
                setCallApi2(false);
                setIsLoading(false);
            })
        }
        if (callApi3) {
            setIsLoading(true);
            GetDataWithToken(queryString3).then(response => {
                setAddressList(response);
                setCallApi3(false);
                setIsLoading(false);
            })
        }
    }, [callApi1, callApi2, callApi3])

    return (
        <>
            <Layout>
                <Heading name="Checkout"
                // breadCrumb1={"Checkout"}
                />
                <div className="content-body">
                    <div className="row">
                        <div class="login-area-2">
                            <div class="d-table">
                                <div class="d-table-cell">

                                    <div class="login-form-profile">
                                        <h4>you have ordered {location?.state?.data?.length} your total amount is {totalAmount && parseFloat(totalAmount).toFixed(2)} </h4>
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <p className="ms-2 d-flex align-items-center justify-content-between" >
                                                <button
                                                    className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                                                    onClick={() => modalOpenHandler("transport")}
                                                    // onClick={() => setNextPage(1)}
                                                    // onClick={() => { setNextPage(1); setCallApi(true) }}
                                                    type="button">Transport</button>
                                                <p className="mb-0 d-flex align-items-center text-nowrap">
                                                    {transportName && `${transportName}`}
                                                    {transportName && <button className="btn btn-primary ms-2" onClick={() => { setTransportName(""); setTransportCode("") }}>X</button>}
                                                </p>
                                            </p>
                                            <hr />
                                            <p className="ms-2 d-flex align-items-center justify-content-between" >
                                                <button
                                                    className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                                                    onClick={() => modalOpenHandler("shipment")}
                                                    // onClick={() => setNextPage(1)}
                                                    // onClick={() => { setNextPage(1); setCallApi(true) }}
                                                    type="button">shipment</button>
                                                <p className="mb-0 d-flex align-items-center text-nowrap">
                                                    {shipmentName && `${shipmentName}`}
                                                    {shipmentName && <button className="btn btn-primary ms-2" onClick={() => { setShipmentName(""); setShipmentCode(""); }}>X</button>}
                                                </p>
                                            </p>
                                            <hr />
                                            <p className="ms-2 d-flex align-items-center justify-content-between flex-wrap" >
                                                <button
                                                    className="btn btn-link text-decoration-none text-dark p-0 d-block w-100 text-start"
                                                    onClick={() => modalOpenHandler("address")}
                                                    // onClick={() => setNextPage(1)}
                                                    // onClick={() => { setNextPage(1); setCallApi(true) }}
                                                    type="button">Address</button>
                                                <p className="mb-0 d-flex align-items-center text-wrap text-start">
                                                    {addressCode.name && `${addressCode.name}`}
                                                    {addressCode.name && <button className="btn btn-primary ms-2" onClick={() => { setAddressCode({ name: "", code: "" }) }}>X</button>}
                                                </p>
                                            </p>
                                            <hr />
                                            <div class="form-group">
                                                <input type="text"
                                                    class="form-control"
                                                    name="mobile"
                                                    placeholder="Remarks"
                                                    onChange={(e) => setRemarks(e.target.value)}
                                                // {...register("remarks", {
                                                //     required: true,
                                                //     maxLength: 80,
                                                // })}
                                                />
                                                {/* <span class="label-title"><i class='bx bx-globe'></i></span> */}
                                            </div>

                                            {/* <div class="form-group">
                                        <select className="form-control" {...register("transport", {
                                            required: true,

                                        })}>
                                            <option value={null}>Transport...</option>
                                            {transportList?.map((data) => <option value={`${data?.Code}%#%#%${data?.Name}`}>{data?.Name}</option>)}
                                        </select>

                                        <span class="label-title"><i class=' bx bxs-car-wash'></i></span>
                                    </div> */}
                                            {/* <div class="form-group">
                                        <select className="form-control"
                                            {...register("shipment", {
                                                required: true,
                                                maxLength: 80,
                                            })}
                                        >
                                            <option value={null}>Shipment...</option>
                                            {shipmentList?.map((data) => <option value={`${data?.Code}%#%#%${data?.Name}`}>{data?.Name}</option>)}
                                        </select>
                                        <span class="label-title"><i class=' bx bx-current-location'></i></span>
                                    </div> */}
                                            {/* <div class="form-group">
                                        <select className="form-control"
                                            {...register("Address", {
                                                required: true,
                                                maxLength: 80,
                                            })}
                                        >
                                            <option value={null}>Address...</option>
                                            {addressList?.map((data) => <option value={data?.Code}>{data?.Name}</option>)}
                                        </select>
                                        <span class="label-title"><i class='
                                        bx bx-mail-send
                                       '></i></span>
                                    </div> */}
                                            {/* <div class="form-group">
                                        <input type="text"
                                            class="form-control"
                                            name="mobile"
                                            placeholder="Remarks"
                                            {...register("remarks", {
                                                required: true,
                                                maxLength: 80,
                                            })}
                                        />
                                        <span class="label-title"><i class='bx bx-globe'></i></span>
                                    </div> */}
                                            <button type="submit" class="btn btn-primary">Checkout</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <CheckoutOptionsModal
                optionType={optionType}
                modalToggle={modalToggle}
                isOpen={isOpen}
                setCallApi1={setCallApi1}
                setCallApi2={setCallApi2}
                setCallApi3={setCallApi3}
                transportList={transportList}
                shipmentList={shipmentList}
                addressList={addressList}
                isLoading={isLoading}
                setAddressName={setAddressName}
                setTransportName={setTransportName}
                setShipmentName={setShipmentName}
                setTransportCode={setTransportCode}
                setShipmentCode={setShipmentCode}
                setAddressCode={setAddressCode}
                setSearchText={setSearchText}
            />
            {pageLoading && <PageLoader />}
        </>
    )
}

export default Checkout;