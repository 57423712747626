import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Login from "../Pages/Auth/Login";
import MyProfile from "../Pages/MyProfile";
import Quotation from "../Pages/Quotation";
import Invoices from "../Pages/Invoices";
import Orders from "../Pages/Orders";
import SearchStock from "../Pages/SearchStock";
import CustomerLedger from "../Pages/CustomerLedger";
import SearchStockDetail from "../Pages/SearchStockDetail";
import OrderDetails from "../Pages/OrderDetails";
import InvoiceDetail from "../Pages/InvoiceDetail";
import QuotationDetail from "../Pages/QuotationDetail";
import Signup from "../Pages/Auth/Signup";
import ProtectedLoginRoute from "../Pages/Auth/ProtectedLoginRoute";
import ProtectedRoute from "../Pages/Auth/ProtectedRoute";
import Cart from "../Pages/Cart";
import Checkout from "../Pages/Checkout";
import QuotationCheckout from "../Pages/QuotationCheckout";
import AlternateItems from "../Pages/AlternateItems";

const CommonRoutes = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL} >
            <Routes>
                <Route element={<ProtectedLoginRoute />}>
                    <Route path="/" element={<Login />} />
                    <Route path="/sign-up" element={<Signup />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                    <Route path="/quotation-checkout" element={<QuotationCheckout />} />
                    <Route path="/alternate-items" element={<AlternateItems />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/profile" element={<MyProfile />} />
                    <Route path="/quotation" element={<Quotation />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/search-stock" element={<SearchStock />} />
                    <Route path="/search-stock-detail" element={<SearchStockDetail />} />
                    <Route path="/customer-ledger" element={<CustomerLedger />} />
                    <Route path="/order-detail" element={<OrderDetails />} />
                    <Route path="/invoice-detail" element={<InvoiceDetail />} />
                    <Route path="/quotation-detail" element={<QuotationDetail />} />
                    <Route path="/checkout" element={<Checkout />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
export default CommonRoutes;