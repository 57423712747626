import { Nav, NavItem, NavLink, TabContent, TabPane, Table } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { GetDataWithToken, PostDataWithToken } from "../ApiHelper/ApiHelper";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import SelectQuantityModal from "../Components/Modals/SelectQuantityModal";
import { useDispatch } from "react-redux";
import { cartData } from "../store/actions/UserActions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

const SearchStockDetail = () => {
  // const selector = useSelector();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [imgArray, setImgArray] = useState([]);
  const reduxData = useSelector((state) => state.user.cartData);
  const roleType = useSelector((state) => state?.user?.userDetails?.Role);
  // console.log("rolleeee",roleType);
  const defaultCompany = useSelector(
    (state) => state?.user?.userDetails?.DefaultCompany
  );
  // console.log("ksdjjfasj", reduxData);
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabOpen, setTabOpen] = useState("1");
  const [tabOpen2, setTabOpen2] = useState(1);
  const [detailsData, setDetailsData] = useState();
  const [companyCode, setCompanyCode] = useState(defaultCompany);
  const [tableData, setTableData] = useState({});
  const [callApi, setCallApi] = useState(true);
  const [callApi2, setCallApi2] = useState(false);
  const [callApi3, setCallApi3] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [isErrorImage1, setIsErrorImage1] = useState(false);
  const [alternateData, setAlternateData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [openAlternateTab, setOpenAlternateTab] = useState(false);
  const [altData, setAltData] = useState();
  const [colorCode, setColorCode] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  const [productLook, setProductLook] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);
  const [quantityNo, setQuantityNo] = useState();
  const modalToggle = () => setModalOpen(!modalOpen);
  const [show, setShow] = useState({
    purchaseShow: false,
    mrpShow: false,
    dpShow: false,
  });
  // console.log('================================', location.state);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === imgArray.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? imgArray.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const setTabValue = (value) => {
    setTabOpen(value);
  };

  const setTabValue2 = (value) => {
    setTabOpen2(value);
  };

  const viewHandler = (data) => {
    setAltData(data);
    setCallApi(true);
    setOpenAlternateTab(false);
    modalToggle();
    setQuantityNo();
    setCallApi3(true);
  };

  const submitSearchData = () => {
    setCallApi2(true);
  };

  let quantityData = {
    Company: defaultCompany,
    CurrentAvailable: detailsData?.QtyInv ?
      +detailsData?.QtyInv?.toFixed(2) : 0,
    ItemCode: detailsData?.Code,
    ItemName: detailsData?.SearchName,
    Quantity: quantityNo,
  };

  let itemLogSearch = {
    Company: defaultCompany,
    ItemCode: detailsData?.Code,
    Qty: quantityNo,
  };

  const alternateButtonHandler = () => {
    setOpenAlternateTab(true);
    setCallApi2(true);
  };

  const addToCartHandler = () => {
    // console.log("hellooo.", reduxData);
    let submitArray = [];
    const submitData = {
      Code: tableData?.Code,
      ItemCode: tableData?.Code,
      Name: detailsData?.Name,
      Qty: quantityNo,
      totalPrice: quantityNo * tableData?.DP,
      UOM: tableData?.UOM,
      DP: tableData?.DP,
    };
    if (reduxData && reduxData?.length > 0) {
      submitArray = [...reduxData];
    } else {
      submitArray = [];
    }

    submitArray.push(submitData);
    // console.log(submitArray);
    // console.log("redux dataaa",reduxData)
    dispatch(cartData(submitArray));
    // dispatch(cartData([...reduxData, submitData]));
    toast.success("item has been successfully to the cart");
  };

  const showHandler = (data) => {
    data === "purchase" &&
      setShow({ ...show, purchaseShow: !show.purchaseShow });
    data === "mrp" && setShow({ ...show, mrpShow: !show.mrpShow });
    data === "dp" && setShow({ ...show, dpShow: !show.dpShow });
  };
  // const imgArray=[];
  // useEffect(()=>{


  // },[])

  const handleImageError = (url) => {
    setImgArray((prevImgArray) => prevImgArray.filter((item) => item !== url));
  };
  // const newImgArray = [...imgArray];
  // newImgArray.splice(index, 1);
  // setImgArray(newImgArray);



  useEffect(() => {
    console.log('newwwwwwwww wwwwwwwwwwwww  location', location?.state);
    if (callApi) {
      GetDataWithToken(
        `api/items/common-details?code=${location?.state?.id}`
      ).then((response) => {
        setDetailsData(response);
        setCallApi(false);
        const updatedImgArray = [];
        for (let i = 0; i <= 3; i++) {
          if (i == 0) {
            updatedImgArray.push(
              `https://www.fandf.online/FloorAndFurnishingsBackEndImg/ProductImageColor/${response?.Code}.jpg`
            );
          } else {
            updatedImgArray.push(
              `https://www.fandf.online/FloorAndFurnishingsBackEndImg/ProductImageColor/${response?.Code}-${i}.jpg`
            );
          }
          // console.log("helllya", updatedImgArray[i]);
        }
        setImgArray((prevData) => [...prevData, ...updatedImgArray]);
        // console.log("imgg...", imgArray);
        // console.log("imgg...",imgArray);
      });
    }

    if (callApi) {
      GetDataWithToken(
        `api/items/details?code=${location?.state?.id}&companyCode=${companyCode}`
      ).then((response) => {
        setTableData(response);
        setColorCode(response?.Color);
        setCategoryCode(response?.CategoryCode);
        setProductLook(response?.ProductLook);
        setCallApi(false);
      });
    }

    if (callApi3) {
      PostDataWithToken(`api/items/log-quantity`, quantityData).then(
        (response) => setCallApi3(false)
      );
      PostDataWithToken(`api/items/log-item-search`, itemLogSearch).then(
        (response) => setCallApi3(false)
      );
    }

    if (callApi2) {
      GetDataWithToken(
        `api/items/alternate-items?productLook=${productLook}&categoryCode=${categoryCode}&colorCode=${colorCode}&pageNo=1&pageSize=10`
      ).then((response) => {
        setAlternateData((prevResponse) => [...prevResponse, ...response]);
        setCurrentPage((prevPage) => prevPage + 1);
        setCallApi2(false);
        setIsLoading(false);
      });
    }
  }, [callApi, callApi2, callApi3]);

  // console.log("idfa", imgArray);

  const backButtonHandler = () => {
    if (location?.state?.tabId) {
      navigate("/search-stock", { state: { tabId: location?.state?.tabId, CategoryCode: location?.state?.CategoryCode, searchText: location?.state?.searchText } });
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      <Layout>

        {!openAlternateTab && (
          <div className="content-body">
            <div className="container-fluid">
              {/* <Heading 
                        name="Item details"
                        //  breadCrumb1="search-stock"
                        //   breadCrumb2="search-stock-details" 
                        /> */}
              <div className="row">

                <div className="col-12 px-0">
                  <div className="card p-3">
                    <div class="row">
                      <div className="mb-3">
                        <span
                          onClick={backButtonHandler}
                          className="btn btn-primary rounded-circle">
                          <span className="icon">
                            <i class='bx bx-left-arrow-alt'></i>
                          </span>
                        </span>
                      </div>
                      <div class="col-12 col-lg-6 col-md-5">
                        <div className="row">
                          {imgArray?.length > 0 && (
                            <Carousel
                              activeIndex={activeIndex}
                              next={next}
                              previous={previous}
                              className=""
                            // {...args}
                            >
                              <CarouselIndicators
                                items={imgArray}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                              />
                              {imgArray?.map((item, index) => {
                                return (
                                  <CarouselItem
                                    onExiting={() => setAnimating(true)}
                                    onExited={() => setAnimating(false)}
                                    key={index}
                                  >
                                    <div style={{ height: "300px" }}>
                                      <img
                                        src={item}
                                        width="100%"
                                        height="50px"
                                        onError={() => handleImageError(item)}
                                        alt="no image found"
                                      />
                                    </div>
                                    {/* <CarouselCaption
          captionText={"hello"}
          captionHeader={"1"}
        /> */}
                                  </CarouselItem>
                                );
                              })}
                              <CarouselControl
                                direction="prev"
                                directionText="Previous"
                                onClickHandler={previous}
                              />
                              <CarouselControl
                                direction="next"
                                directionText="Next"
                                onClickHandler={next}
                              />
                            </Carousel>
                          )}
                          <div
                          // style={{ marginLeft: '10%', marginTop: '10%' }}
                          >
                            <div
                              className="d-flex flex-column justify-content-center align-items-start align-items-lg-center"
                            // className={styles.col_1}
                            >
                              {/* {detailsData?.Code && !isErrorImage && (
                                <img
                                  src={`https://www.fandf.online/FloorAndFurnishingsBackEndImg/ProductImageColor/${detailsData?.Code}-1.jpg`}
                                  // {`http://trade.fandf.in/Images/Items/.jpg`}
                                  alt="no image found"
                                  onError={(e) => {
                                    // console.log( "abfbababababababababababababab",e);
                                    setIsErrorImage(true);
                                    // e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png'
                                  }}
                                  width="50%"
                                />
                              )} */}
                              {/* {detailsData?.Code && !isErrorImage1 && (
                                <img
                                  src={`https://www.fandf.online/FloorAndFurnishingsBackEndImg/ProductImageColor/${detailsData?.Code}.jpg`}
                                  // {`http://trade.fandf.in/Images/Items/.jpg`}
                                  alt="no image found"
                                  onError={(e) => {
                                    // console.log( "abfbababababababababababababab",e);
                                    setIsErrorImage1(true);
                                    // e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png'
                                  }}
                                  width="50%"
                                />
                              )} */}
                              {imgArray?.length == 0 && (
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "10px",
                                    padding: "2rem",
                                    textAlign: "center",
                                    width: "250px",
                                    height: "150px",
                                  }}
                                >
                                  <h5> NO IMAGE FOUND</h5>
                                </div>
                              )}
                              <Link
                                className="btn btn-primary my-1"
                                to="/alternate-items"
                                state={{
                                  productLook: productLook,
                                  categoryCode: categoryCode,
                                  colorCode: colorCode,
                                }}
                              //  onClick={alternateButtonHandler}
                              >
                                FIND ALTERNATE ITEMS
                              </Link>
                            </div>
                          </div>
                          <div className="justify-content-center align-items-start  align-items-lg-center">
                            {roleType === "DEALER" && (
                              <div className="col-12">
                                <div className="my-2 d-flex justify-content-lg-center align-item-center">
                                  <button
                                    className="btn btn-primary mx-1"
                                    onClick={() =>
                                      setQuantityNo(
                                        (prevState) => prevState - 1
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="cart-input"
                                    type="number"
                                    value={quantityNo}
                                    step="any"
                                    min={"0"}
                                    onChange={(e) => setQuantityNo(e.target.value)}
                                  />
                                  <button
                                    onClick={() =>
                                      setQuantityNo((prevState) => ++prevState)
                                    }
                                    className="btn btn-primary  mx-1"
                                  >
                                    +
                                  </button>
                                  {quantityNo > 0 && tableData?.Code && (
                                    <button
                                      className="btn btn-primary mx-2"
                                      onClick={addToCartHandler}
                                    >
                                      Add to cart
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 col-md-7">
                        <div
                        // className={styles.col_2}
                        >
                          <h4 className="card-title custom-heading">
                            {detailsData?.Name}
                          </h4>
                          <ul class="list-group list-group-flush ps-2 border-bottom mb-3">
                            <li className="d-flex align-items-center">
                              <p className="me-2 mb-2">
                                <strong>PRODUCT CODE:</strong>
                              </p>
                              <p className=" mb-2">{detailsData?.Code}</p>
                            </li>
                            <li className="d-flex align-items-center">
                              <p className="me-2 mb-2">
                                <strong>SEARCH NAME:</strong>
                              </p>
                              <p className=" mb-2">{detailsData?.SearchName}</p>
                            </li>
                            <li className="d-flex align-items-center">
                              <p className="me-2 mb-2">
                                <strong>CURRENT STOCK:</strong>
                              </p>
                              <p className=" mb-2">
                                {detailsData?.QtyInv &&
                                  +detailsData?.QtyInv?.toFixed(2)}
                              </p>
                            </li>
                          </ul>
                          <Nav tabs pills className="border-bottom-0 mb-3" fill>
                            <NavItem>
                              {defaultCompany === "FFI" ? (
                                <NavLink
                                  className={tabOpen === "1" ? "active" : ""}
                                  onClick={() => {
                                    setTabValue("1");
                                    setCompanyCode("ffi");
                                    setCallApi(true);
                                  }}
                                >
                                  FFI
                                </NavLink>
                              ) : (
                                <NavLink
                                  className={tabOpen === "1" ? "active" : ""}
                                  onClick={() => {
                                    setTabValue("1");
                                    setCompanyCode("ffg");
                                    setCallApi(true);
                                  }}
                                >
                                  FFG
                                </NavLink>
                              )}
                            </NavItem>
                            <NavItem>
                              {defaultCompany === "FFI" ? (
                                <NavLink
                                  className={tabOpen === "2" ? "active" : ""}
                                  onClick={() => {
                                    setTabValue("2");
                                    setCompanyCode("ffg");
                                    setCallApi(true);
                                  }}
                                >
                                  FFG
                                </NavLink>
                              ) : (
                                <NavLink
                                  className={tabOpen === "2" ? "active" : ""}
                                  onClick={() => {
                                    setTabValue("2");
                                    setCompanyCode("ffi");
                                    setCallApi(true);
                                  }}
                                >
                                  FFI
                                </NavLink>
                              )}
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={tabOpen}>
                            <TabPane tabId="1">
                              <ul className="ps-2 border-bottom mb-3">
                                <li class="list-group-item d-flex justify-content-between ">
                                  <p className="mb-2 me-2">
                                    <strong> Item family code:</strong>
                                  </p>
                                  <p class="mb-2">
                                    {tableData?.ItemFamilyCode}
                                  </p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Reserved Qty:</strong>
                                  </p>
                                  <p class="mb-2">
                                    {tableData?.QtySO &&
                                      +tableData?.QtySO?.toFixed(2)}
                                  </p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Current Available:</strong>
                                  </p>
                                  <p class="mb-2">{tableData?.QtyFree}</p>
                                </li>
                                {roleType === "ADMIN" ? (
                                  <>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Vendor Group:</strong>
                                      </p>
                                      <p class="mb-2">
                                        {tableData?.VendorGroup}
                                      </p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Primary vendor:</strong>
                                      </p>
                                      <p class="mb-2">
                                        {tableData?.PrimaryVendorName}
                                      </p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Contact No:</strong>
                                      </p>
                                      <p class="mb-2">{tableData?.ContactNo}</p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>External Item No:</strong>
                                      </p>
                                      <p class="mb-2">
                                        {tableData?.ExternalItemId}
                                      </p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Purchase Price:</strong>
                                        <button
                                          class="btn btn-primary px-2 py-1 rounded-pill ms-2"
                                          // style={{padding:"1px 4px"}}
                                          onClick={() =>
                                            showHandler("purchase")
                                          }
                                        >
                                          {show?.purchaseShow === false
                                            ? "show"
                                            : "hide"}
                                        </button>
                                      </p>
                                      <p class="mb-2">
                                        {show?.purchaseShow === true &&
                                          tableData?.PurchasePrice &&
                                          Number(
                                            tableData?.PurchasePrice
                                          )?.toFixed(2)}
                                      </p>
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>MRP:</strong>
                                    <button
                                      class="btn btn-primary px-2 py-1 rounded-pill ms-2"
                                      onClick={() => showHandler("mrp")}
                                    >
                                      {show?.mrpShow === false
                                        ? "show"
                                        : "hide"}
                                    </button>
                                  </p>

                                  <p class="mb-2">
                                    {show?.mrpShow === true &&
                                      tableData?.MRP &&
                                      +tableData?.MRP?.toFixed(2)}
                                  </p>
                                </li>
                                {roleType === "ADMIN" ||
                                  roleType === "DEALER" ||
                                  roleType === "SALES_PERSON" ? (
                                  <li class="list-group-item d-flex justify-content-between">
                                    <p className="mb-2 me-2">
                                      <strong>DP:</strong>
                                      <button
                                        class="btn btn-primary px-2 py-1 rounded-pill ms-2"
                                        onClick={() => showHandler("dp")}
                                      >
                                        {show?.dpShow === false
                                          ? "show"
                                          : "hide"}
                                      </button>
                                    </p>

                                    <p class="mb-2">
                                      {show?.dpShow === true &&
                                        tableData?.DP &&
                                        +tableData?.DP?.toFixed(2)}
                                    </p>
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </TabPane>
                            <TabPane tabId="2">
                              <ul className="ps-2 border-bottom mb-3">
                                <li class="list-group-item d-flex justify-content-between ">
                                  <p className="mb-2 me-2">
                                    <strong> Item family code:</strong>
                                  </p>
                                  <p class="mb-0">
                                    {tableData?.ItemFamilyCode}
                                  </p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Reserved Qty:</strong>
                                  </p>
                                  <p class="mb-0">
                                    {tableData?.QtySO &&
                                      tableData?.QtySO?.toFixed(2)}
                                  </p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Current Available:</strong>
                                  </p>
                                  <p class="mb-0">0</p>
                                </li>
                                {roleType === "ADMIN" ? (
                                  <>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Vendor Group:</strong>
                                      </p>
                                      <p class="mb-0">
                                        {tableData?.VendorGroup}
                                      </p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Primary vendor:</strong>
                                      </p>
                                      <p class="mb-0">
                                        {tableData?.PrimaryVendorName}
                                      </p>
                                    </li>{" "}
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Contact No:</strong>
                                      </p>
                                      <p class="mb-0">{tableData?.ContactNo}</p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>External Item No:</strong>
                                      </p>
                                      <p class="mb-0">
                                        {tableData?.ExternalItemId}
                                      </p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between">
                                      <p className="mb-2 me-2">
                                        <strong>Purchase Price:</strong>
                                        <button
                                          class="btn btn-primary px-2 py-1 rounded-pill ms-2"
                                          onClick={() =>
                                            showHandler("purchase")
                                          }
                                        >
                                          {show?.purchaseShow === false
                                            ? "show"
                                            : "hide"}
                                        </button>
                                      </p>

                                      <p class="mb-0">
                                        {show?.purchaseShow === true &&
                                          tableData?.PurchasePrice &&
                                          Number(
                                            tableData?.PurchasePrice
                                          )?.toFixed(2)}
                                      </p>
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>MRP:</strong>
                                    <button
                                      class="btn btn-primary px-2 py-1 rounded-pill ms-2"
                                      onClick={() => showHandler("mrp")}
                                    >
                                      {show?.mrpShow === false
                                        ? "show"
                                        : "hide"}
                                    </button>
                                  </p>
                                  <p class="mb-0">
                                    {show?.mrpShow === true &&
                                      tableData?.MRP &&
                                      +tableData?.MRP?.toFixed(2)}
                                  </p>
                                </li>
                                {roleType === "ADMIN" ||
                                  roleType === "DEALER" ||
                                  roleType === "SALES_PERSON" ? (
                                  <li class="list-group-item d-flex justify-content-between">
                                    <p className="mb-2 me-2">
                                      <strong>DP:</strong>
                                      <button
                                        class="btn btn-primary px-2 py-1 rounded-pill ms-2"
                                        onClick={() => showHandler("dp")}
                                      >
                                        {show?.dpShow === false
                                          ? "show"
                                          : "hide"}
                                      </button>
                                    </p>
                                    <p class="mb-2">
                                      {show?.dpShow === true &&
                                        tableData?.DP &&
                                        +tableData?.DP?.toFixed(2)}
                                    </p>
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </TabPane>
                          </TabContent>

                          <Nav tabs pills className="border-bottom-0 mb-3" fill>
                            <NavItem>
                              <NavLink
                                className={tabOpen2 === 1 ? "active" : ""}
                                onClick={() => {
                                  setTabValue2(1);
                                  // // setCompanyCode('ffg');
                                  // setCallApi(true);
                                }}
                              >
                                PO DETAILS
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={tabOpen2 === 2 ? "active" : ""}
                                onClick={() => {
                                  setTabValue2(2);
                                  // setCompanyCode('ffi');
                                  //  setCallApi(true);
                                }}
                              >
                                TECHNICAL DETAILS
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={tabOpen2}>
                            <TabPane tabId={1}>
                              <ul className="ps-2 border-bottom mb-3">
                                <li class="list-group-item d-flex justify-content-between ">
                                  <p className="mb-2 me-2">
                                    <strong> Qty in PO:</strong>
                                  </p>
                                  <p class="mb-0">{tableData?.QtyPO}</p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Expected:</strong>
                                  </p>
                                  <p class="mb-0">
                                    {tableData?.DeliveryDate &&
                                      moment(tableData?.DeliveryDate)?.format(
                                        "DD/MM/YYYY"
                                      )}
                                  </p>
                                </li>{" "}
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong> Bilty No:</strong>
                                  </p>
                                  <p class="mb-0">{tableData?.BiltyNo}</p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Bilty Date:</strong>
                                  </p>
                                  <p class="mb-0">
                                    {tableData?.BiltyDate &&
                                      moment(tableData?.BiltyDate)?.format(
                                        "DD/MM/YYYY"
                                      )}
                                  </p>
                                </li>{" "}
                                <li class="list-group-item d-flex justify-content-between ">
                                  <p className="mb-2 me-2">
                                    {" "}
                                    <strong> Transport:</strong>
                                  </p>
                                  <p class="mb-0">{tableData?.Transport}</p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Status:</strong>
                                  </p>
                                  <p class="mb-0">
                                    {tableData?.DeliveryStatus}
                                  </p>
                                </li>
                              </ul>
                            </TabPane>
                          </TabContent>
                          <TabContent activeTab={tabOpen2}>
                            <TabPane tabId={2}>
                              <ul className="ps-2 border-bottom mb-3">
                                <li class="list-group-item d-flex justify-content-between ">
                                  <p className="mb-2 me-2">
                                    <strong>Reorder Qty:</strong>
                                  </p>
                                  <p class="mb-0">{tableData?.ReorderQty}</p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Lead Period:</strong>
                                  </p>
                                  <p class="mb-0">{tableData?.LeadPeriod}</p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between ">
                                  <p className="mb-2 me-2">
                                    <strong>Repeat Vertical:</strong>
                                  </p>
                                  <p class="mb-0">
                                    {tableData?.RepeatVertical}
                                  </p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Repeat Horizontal:</strong>
                                  </p>
                                  <p class="mb-0">
                                    {tableData?.RepeatHorizontal}
                                  </p>
                                </li>{" "}
                                <li class="list-group-item d-flex justify-content-between ">
                                  <p className="mb-2 me-2">
                                    <strong> Width:</strong>
                                  </p>
                                  <p class="mb-0">{tableData?.Width}</p>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                  <p className="mb-2 me-2">
                                    <strong>Length:</strong>
                                  </p>
                                  <p class="mb-0">{tableData?.Length}</p>
                                </li>
                              </ul>
                            </TabPane>
                          </TabContent>

                          {detailsData?.Batches?.length > 0 && (
                            <Table className="mt-2 table table-striped table-borderless">
                              <thead>
                                <tr>
                                  <th>Batch</th>
                                  <th>Loc</th>
                                  <th>Company</th>
                                  <th>Qty</th>
                                </tr>
                              </thead>
                              <tbody>
                                {detailsData?.Batches?.map((data) => (
                                  <tr>
                                    <td>{data?.Code}</td>
                                    <td>{data?.LocId}</td>
                                    <td>{data?.Company}</td>
                                    <td>
                                      {data?.Qty && +data?.Qty?.toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
      {(roleType === "DEALER" || roleType === "SALES_PERSON" || roleType === "GUEST") && (
        <SelectQuantityModal
          toggle={modalToggle}
          openModal={modalOpen}
          setQuantityNo={setQuantityNo}
          quantityNo={quantityNo}
          mainSetCallApi={setCallApi3}
        />
      )}
    </>
  );
};
export default SearchStockDetail;
