import moment from "moment";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Common/PrimaryButton";

const TableComponent = ({
  data,
  navigation,
  isLoading,
  tableHeading,
  quotation,
}) => {
  const navigate = useNavigate();
  const navigationFunction = (code) => {
    navigate(navigation, {
      state: {
        id: code,
      },
    });
  };
  return (
    <div className="table-responsive">
      <table
        className="table card-table display mb-5 shadow-hover table-responsive-sm table-hover"
        style={{ minWidth: "200px" }}
      >
        <thead>
          {/*                   
          {!tableHeading && <tr>
            <th>Code</th>
           {!quotation &&<th>Date</th>}
            <th>Name</th>
            <th>Action</th>
          </tr>} */}
          <tr>
            {tableHeading && tableHeading?.map((item) => <th>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {data && !isLoading && data?.length == 0 ? (
            <h3
              style={{
                position: "absolute",
                left: "40%",
                padding: "10px",
              }}
            >
              No data found
            </h3>
          ) : (
            data?.map((data, index) => (
              <tr
               style={{cursor:"pointer"}}
              onClick={() =>
                navigationFunction(data?.Code ? data?.Code : data?.No)
              }>
                <td>{data?.Code}</td>
                {data?.OrderDate && (
                  <td>
                    {data?.OrderDate &&
                      moment(data?.OrderDate).format("DD/MM/YYYY")}
                  </td>
                )}
                {data?.QuotationDate && (
                  <td>{moment(data?.QuotationDate).format("DD/MM/YYYY")}</td>
                )}
                {data?.Name ||
                  (data?.DeliveryName && (
                    <td>{data?.Name ? data?.Name : data?.DeliveryName}</td>
                  ))}
                {data?.Customer?.Name && <td>{data?.Customer?.Name}</td>}
                {data?.CollectionCode && <td>{data?.CollectionCode}</td>}
                {data?.QtyInv && (
                  <td>{data?.QtyInv && +data?.QtyInv.toFixed(2)}</td>
                )}
                {data?.BatchCount && (
                  <td>{data?.BatchCount > 0 ? data?.BatchCount : 0}</td>
                )}
                {/* <td>
                  <PrimaryButton
                    name="view"
                    clickEvent={() =>
                      navigationFunction(data?.Code ? data?.Code : data?.No)
                    }
                  />
                 
                </td> */}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
export default TableComponent;
