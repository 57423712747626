import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
const Header = ({toggleSidebar,showSidebar}) => {
  const [scrolled, setScrolled] = useState(false);
  const roleType = useSelector((state) => state?.user?.userDetails?.Role);
  const cartItem = useSelector((state) => state?.user?.cartData);
  const navigate = useNavigate();
//   const logoutHandler = () => {
//     Cookies.remove("F&ftToken");
//     navigate("/");
//   };

const sidebarHandler=()=>{
  toggleSidebar();
} 

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 100) {
        // Adjust the scroll threshold as needed
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    // Add event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //  window.addEventListener('scroll', () => {
  //     const header = document.getElementById('myHeader');
  //     if (window.scrollY > 100) { // Adjust the scroll threshold as needed
  //         header.classList.add('is-sticky');
  //     } else {
  //         header.classList.remove('is-sticky');
  //     }
  // });
  const headerClasses = scrolled
    ? "navbar top-navbar navbar-expand is-sticky"
    : "navbar top-navbar navbar-expand";

  return (
    <nav className={headerClasses} id="myHeader">
          <div className="d-block d-lg-none">
          {/* canvas start */}
           

          {/* canvas end */}
           {/* <ul style={{display:"flex",listStyle:"none"}}>
            <li>
            <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-item"
                : isActive
                  ? "nav-item mm-active"
                  : "nav-item"
            }
            to="/home"
          >
            <div className="nav-link mx-2">
              <span class="icon">
                <i class="bx bx-home-circle" style={{fontSize:" 20px"}}></i>
              </span>
              <span class="menu-title">Dashboard</span>
            </div>
          </NavLink>
            </li>
            <li>
            {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ||
            roleType === "GUEST" ? (
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
             
              activeClassName="mm-active"

              to="/search-stock"
            >
              <div className="nav-link">
                <span class="icon">
                  <i class=" bx bx-search-alt "style={{fontSize:" 20px"}}></i>
                </span>
                <span class="menu-title">Search Stock</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
            </li>
            <li>
            {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ? (
            <NavLink
              to="/orders"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bx-menu-alt-left"style={{fontSize:" 20px"}}></i>
                </span>
                <span class="menu-title">Orders</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
            </li>
            <li>
            {roleType === "ADMIN" || roleType === "SALES_PERSON" ? (
            <NavLink
              to="/quotation"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bxs-notepad" style={{fontSize:" 20px"}}></i>
                </span>
                <span class="menu-title">Quotation</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
            </li>
            <li>
            {roleType === "DEALER" && (
            <NavLink
              to="/cart"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <div className="d-flex justify-content-between ">
                  <div>
                    {" "}
                    <span class="icon">
                      <i class="bx bxs-cart "style={{fontSize:" 20px"}}></i>
                      
                    </span>
                    <span className="badge badge-success  p-1">
                    {cartItem?.length > 0 && cartItem?.length}
                  </span>
                    <span class="menu-title">Cart</span>{" "}
                  </div>

               
                </div>
              </div>
            </NavLink>
          )}
            </li>
            <li>
            {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ? (
            <NavLink
              to="/invoices"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bxs-dashboard" style={{fontSize:" 20px"}}></i>
                </span>
                <span class="menu-title">Invoices</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
            </li>
            <li>
            {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ? (
            <NavLink
              to="/customer-ledger"
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
            >
              <div className="nav-link">
                <span class="icon">
                  <i class="bx bx-book-reader " style={{fontSize:" 20px"}}></i>
                </span>
                <span class="menu-title">Customer Ledger</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
            </li>
            <li>
            {roleType === "ADMIN" ||
            roleType === "DEALER" ||
            roleType === "SALES_PERSON" ||
            roleType === "GUEST" ? (
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item"
                  : isActive
                    ? "nav-item mm-active"
                    : "nav-item"
              }
              className="nav-item"
              activeClassName="mm-active"

              to="/profile"
            >
              <div className="nav-link">
                <span class="icon">
                  <i class=" bx bx-user" style={{fontSize:" 20px"}}></i>
                </span>
                <span class="menu-title">My Profile</span>
              </div>
            </NavLink>
          ) : (
            ""
          )}
            </li>
           </ul> */}
               
        </div>
      <div
        class="collapse navbar-collapse"
        style={{ justifyContent: "start" }}
        id="navbarSupportContent"
      >
      
        <div class={showSidebar?"responsive-burger-menu d-lg-none ":"responsive-burger-menu d-lg-none active"} onClick={sidebarHandler}>
           
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </div>
        {/* <ul class="navbar-nav left-nav align-items-center">
                        <li class="nav-item">
                            <a href="app-email.html" class="nav-link" data-bs-toggle="tooltip" data-placement="bottom" title="Email">
                                <i class="bx bx-envelope"></i>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a href="app-chat.html" class="nav-link" data-bs-toggle="tooltip" data-placement="bottom" title="Message">
                                <i class='bx bx-message'></i>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a href="app-calendar.html" class="nav-link" data-bs-toggle="tooltip" data-placement="bottom" title="Calendar">
                                <i class='bx bx-calendar'></i>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a href="app-todo.html" class="nav-link" data-bs-toggle="tooltip" data-placement="bottom" title="Todo List">
                                <i class='bx bx-edit'></i>
                            </a>
                        </li>

                        <li class="nav-item dropdown apps-box">
                            <button class="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class='bx bxs-grid'></i>
                            </button>

                            <div class="dropdown-menu">
                                <div class="dropdown-header d-flex justify-content-between align-items-center">
                                    <span class="title d-inline-block">Web Apps</span>
                                    <span class="edit-btn d-inline-block">Edit</span>
                                </div>

                                <div class="dropdown-body">
                                    <div class="d-flex flex-wrap align-items-center">
                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-account.png" alt="image"/>
                                            <span class="d-block mb-0">Account</span>
                                        </a>

                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-google.png" alt="image"/>
                                            <span class="d-block mb-0">Search</span>
                                        </a>

                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-map.png" alt="image"/>
                                            <span class="d-block mb-0">Maps</span>
                                        </a>

                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-youtube.png" alt="image"/>
                                            <span class="d-block mb-0">YouTube</span>
                                        </a>

                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-playstore.png" alt="image"/>
                                            <span class="d-block mb-0">Play</span>
                                        </a>

                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-gmail.png" alt="image"/>
                                            <span class="d-block mb-0">Gmail</span>
                                        </a>

                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-drive.png" alt="image"/>
                                            <span class="d-block mb-0">Drive</span>
                                        </a>

                                        <a href="#" class="dropdown-item">
                                            <img src="assets/img/icon-calendar.png" alt="image"/>
                                            <span class="d-block mb-0">Calendar</span>
                                        </a>
                                    </div>
                                </div>

                                <div class="dropdown-footer">
                                    <a href="#" class="dropdown-item">View All</a>
                                </div>
                            </div>
                        </li>
                    </ul> */}

        {/* <div class="nav-search-form d-none ms-auto d-md-block">
                    <label><i class='bx bx-search'></i></label>
                    <input type="text" class="form-control" placeholder="Search here..." />
                </div> */}

        <ul class="navbar-nav right-nav align-items-center">
          {/* <li class="nav-item">
                            <a class="nav-link bx-fullscreen-btn" id="fullscreen-button">
                                <i class="bx bx-fullscreen"></i>
                            </a>
                        </li> */}

          <li class="nav-item dropdown language-switch-nav-item">
            {/* <button class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="assets/img/us-flag.jpg" alt="image"/>
                                <span>English <i class='bx bx-chevron-down'></i></span>
                            </button> */}

            {/* <div class="dropdown-menu">
                                <a href="#" class="dropdown-item d-flex justify-content-between align-items-center">
                                    <span>German</span>

                                    <img src="assets/img/germany-flag.jpg" alt="flag"/>
                                </a>

                                <a href="#" class="dropdown-item d-flex justify-content-between align-items-center">
                                    <span>French</span>

                                    <img src="assets/img/france-flag.jpg" alt="flag"/>
                                </a>

                                <a href="#" class="dropdown-item d-flex justify-content-between align-items-center">
                                    <span>Spanish</span>

                                    <img src="assets/img/spain-flag.jpg" alt="flag"/>
                                </a>

                                <a href="#" class="dropdown-item d-flex justify-content-between align-items-center">
                                    <span>Russian</span>

                                    <img src="assets/img/russia-flag.jpg" alt="flag"/>
                                </a>

                                <a href="#" class="dropdown-item d-flex justify-content-between align-items-center">
                                    <span>Italian</span>

                                    <img src="assets/img/italy-flag.jpg" alt="flag"/>
                                </a>
                            </div> */}
          </li>

          <li class="nav-item message-box dropdown">
            {/* <button class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="message-btn">
                                    <i class='bx bx-envelope'></i>

                                    <span class="badge bg-primary">4</span>
                                </div>
                            </button> */}

            {/* <div class="dropdown-menu">
                                <div class="dropdown-header d-flex justify-content-between align-items-center">
                                    <span class="title d-inline-block">4 New Message</span>
                                    <span class="clear-all-btn d-inline-block">Clear All</span>
                                </div>

                                <div class="dropdown-body">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="figure">
                                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image"/>
                                        </div>

                                        <div class="content d-flex justify-content-between align-items-center">
                                            <div class="text">
                                                <span class="d-block">Sarah Taylor</span>
                                                <p class="sub-text mb-0">UX/UI design</p>
                                            </div>
                                            <p class="time-text mb-0">2 sec ago</p>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="figure">
                                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image"/>
                                        </div>

                                        <div class="content d-flex justify-content-between align-items-center">
                                            <div class="text">
                                            <span class="d-block">Lucy Eva</span>
                                                <p class="sub-text mb-0">Web developers</p>
                                            </div>
                                            <p class="time-text mb-0">5 sec ago</p>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="figure">
                                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image"/>
                                        </div>

                                        <div class="content d-flex justify-content-between align-items-center">
                                            <div class="text">
                                            <span class="d-block">James Anderson</span>
                                                <p class="sub-text mb-0">Content whitter</p>
                                            </div>
                                            <p class="time-text mb-0">3 min ago</p>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="figure">
                                            <img src="assets/img/user4.jpg" class="rounded-circle" alt="image"/>
                                        </div>

                                        <div class="content d-flex justify-content-between align-items-center">
                                            <div class="text">
                                            <span class="d-block">Steven Smith</span>
                                                <p class="sub-text mb-0">Digital marketing</p>
                                            </div>
                                            <p class="time-text mb-0">7 min ago</p>
                                        </div>
                                    </a>
                                </div>

                                <div class="dropdown-footer">
                                    <a href="#" class="dropdown-item">View All</a>
                                </div>
                            </div> */}
          </li>

          {/* <li class="nav-item notification-box dropdown">
                            <button class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="notification-btn">
                                    <i class='bx bx-bell'></i>

                                    <span class="badge bg-secondary">5</span>
                                </div>
                            </button>

                            <div class="dropdown-menu">
                                <div class="dropdown-header d-flex justify-content-between align-items-center">
                                    <span class="title d-inline-block">6 New Notifications</span>
                                    <span class="mark-all-btn d-inline-block">Mark all as read</span>
                                </div>

                                <div class="dropdown-body">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="icon">
                                            <i class='bx bx-message-rounded-dots'></i>
                                        </div>

                                        <div class="content">
                                            <span class="d-block">Just sent a new message!</span>
                                            <p class="sub-text mb-0">2 sec ago</p>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="icon">
                                            <i class='bx bx-user'></i>
                                        </div>

                                        <div class="content">
                                            <span class="d-block">New customer registered</span>
                                            <p class="sub-text mb-0">5 sec ago</p>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="icon">
                                            <i class='bx bx-layer'></i>
                                        </div>

                                        <div class="content">
                                            <span class="d-block">Apps are ready for update</span>
                                            <p class="sub-text mb-0">3 min ago</p>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="icon">
                                            <i class='bx bx-hourglass'></i>
                                        </div>

                                        <div class="content">
                                            <span class="d-block">Your item is shipped</span>
                                            <p class="sub-text mb-0">7 min ago</p>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <div class="icon">
                                            <i class='bx bx-comment-dots'></i>
                                        </div>

                                        <div class="content">
                                            <span class="d-block">Steven commented on your post</span>
                                            <p class="sub-text mb-0">1 sec ago</p>
                                        </div>
                                    </a>
                                </div>

                                <div class="dropdown-footer">
                                    <a href="#" class="dropdown-item">View All</a>
                                </div>
                            </div>
                        </li> */}

          <li class="nav-item dropdown profile-nav-item">
            <button
              href="#"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* <div class="menu-profile">
                <button
                  class="nav-link"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={logoutHandler}
                >
                  <i class="bx bx-log-out pe-1"></i> <span>Logout</span>
                </button>
                <img src="assets/img/user1.jpg" class="rounded-circle" alt="image"/>
              </div> */}
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Header;
