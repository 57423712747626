import { useCallback, useEffect, useRef, useState } from "react";
import Layout from ".././Components/Common/Layout";
import { useInView } from "react-intersection-observer";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import SearchStockModal from "../Components/Modals/SearchStockModal";
import Heading from "../Components/Layouts/Heading";
import { GetDataWithToken } from "../ApiHelper/ApiHelper";
import TableComponent from "../Components/Layouts/TableComponent";
import PrimaryButton from "../Components/Common/PrimaryButton";
import { toast } from "react-toastify";
import QrModal from "../Components/Modals/QrModal";
import { BrowserMultiFormatReader } from '@zxing/library';
import PageLoader from "../Components/Common/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { setReduxSearchData } from "../store/actions/UserActions";
import { set } from "react-hook-form";

const SearchStock = () => {
  const dispatch = useDispatch();
  const userSearchData = useSelector((state) => state?.user?.searchData);
  console.log("dtatatatata...", userSearchData);
  // const [cancelTokenSource, setCancelTokenSource] = useState(null);
  // const [qrModalOpen,setQrModalOpen]=useState(false);
  const [visibleElementshowHandler, setVisibleElementShowHandler] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const qrModalToggle = () => setQrModalOpen(!qrModalOpen);
  const [result, setResult] = useState('No result');
  const navigate = useNavigate();
  const location = useLocation();
  const [tabOpen, setTabOpen] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  // const [totalPage, settotalPage] = useState(1);
  const [data, setdata] = useState([]);
  const [callApi, setCallApi] = useState(true);
  const [searchData, setSearchData] = useState(location?.state?.searchText || "");
  const [searchBrandData, setSearchBrandData] = useState("");
  const [searchCollectionData, setSearchCollectionData] = useState("");
  const [searchStockModalOpen, setSearchStockModalOpen] = useState(false);
  const [catagoryData, setCategoryData] = useState([]);
  const [categoryCode, setCategoryCode] = useState(location?.state?.CategoryCode || "FABRIC");
  const [downloadData, setDownloadData] = useState();
  const modalToggle = () => setSearchStockModalOpen(!searchStockModalOpen);
  const { ref: myRef, inView: visibleElement } = useInView();
  const [cancelTokenSource, setCancelTokenSource] = useState(axios.CancelToken.source());

  // const codeReader = useRef(new BrowserMultiFormatReader());
  // const videoInputRef = useRef(null);
  // const [scannedText, setScannedText] = useState('No QR code scanned yet');

  //  console.log("navigator",window.navigator.mediaDevices)
  //  const handleScan = async () => {
  //   try {
  //     const videoElement = videoInputRef.current;
  //     const result = await codeReader.current.decodeFromVideoDevice(undefined, videoElement);
  //     setScannedText(result.text);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };





  const handleScan = useCallback((data) => {
    if (data) {
      console.log("data", data);
      setResult(data.text);
      navigationFunction(
        data?.text
      )
    }
  }, []);

  const handleError = useCallback((error) => {
    console.error(error);
  }, []);

  const setTabValue = (value, code) => {

    // if (cancelTokenSource) {
    //   cancelTokenSource.cancel("Request canceled by setTabValue");
    // }
    // dispatch(setReduxSearchData(""));
    setVisibleElementShowHandler(true);
    // visibleElementshowHandler
    setSearchData("");
    setTabOpen(value);
    setCategoryCode(code);
    setCallApi(true);
    setIsLoading(true);
    setdata([]);
    setCurrentPage(1);
    setIsLoading(true);
  };

  const tableArray = [
    "Code",
    "Item Name",
    "Collection",
    "Qty Inv",
    "Pieces",
    // "Action",
  ];
  // console.log("responseeeeee", downloadData);

  const navigationFunction = (code) => {
    navigate("/search-stock-detail", {
      state: {
        id: code,
        tabId: tabOpen,
        CategoryCode: categoryCode,
        searchText: searchData,
      },
    });
  };

  const filters = [];

  if (searchData) {
    filters.push(`SearchText:${searchData}`);
  }

  if (categoryCode) {
    filters.push(`CategoryCode:${categoryCode}`);
  }

  if (searchBrandData) {
    filters.push(`BrandCode:${searchBrandData}`);
  }

  if (searchCollectionData) {
    filters.push(`CollectionCode:${searchCollectionData}`);
  }

  const queryString =
    filters.length > 0
      ? `api/items/list?pageNo=${currentPage}&pageSize=10&filters=${filters.join(
        ";"
      )}`
      : `api/items/list?pageNo=${currentPage}&pageSize=10`;

  useEffect(() => {
    console.log("stateee categoryyy...", location?.state)
    if (location?.state?.tabId) {
      setTabValue(location?.state?.tabId);
      setSearchData(location?.state?.searchText);
      setCategoryCode(location?.state?.CategoryCode);
    }
    GetDataWithToken(
      "other/categories?pageNo=1&pageSize=50&orderBy=&filters=&excel=false"
    ).then((response) => {
      setCategoryData(response);
    });
  }, [])

  // let cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    // api/items/list?pageNo=1&pageSize=10&filters=%3BCategoryCode%3AFABRIC
    if (callApi || visibleElement && !isLoading) {
      setIsLoading(true);
      GetDataWithToken(queryString).then((response) => {
        if (response.status === 500) {
          toast.error(response.data);
          setIsLoading(false);
          setCallApi(false);
          setPageLoading(false);
        } else {
          setPageLoading(false);
          setIsLoading(false);
          setCallApi(false);
          if (response?.length === 0) {
            setVisibleElementShowHandler(false);
            // console.log('No more data to fetch');
          } else {
            setdata((prevData) => [...prevData, ...response]);
            setCurrentPage((prevPage) => prevPage + 1);
          }
        }
      });
    }
  }, [callApi, visibleElement]);

  // useEffect(() => {
  //   cancelTokenSource.cancel("Request canceled by user"); // Cancel previous request
  //   const newCancelTokenSource = axios.CancelToken.source(); // Create new cancel token source
  //   setCancelTokenSource(newCancelTokenSource);
  //   setdata([]);
  //   setIsLoading(true);
  //   setCurrentPage(1);
  //   GetDataWithToken(queryString, newCancelTokenSource.token).then((response) => {
  //     if (response?.status === 500) {
  //       toast.error(response.data);
  //       setIsLoading(false);
  //       setCallApi(false);
  //       setPageLoading(false);
  //     } else {
  //       setPageLoading(false);
  //       setIsLoading(false);
  //       setCallApi(false);
  //       if (response?.length === 0) {

  //         setVisibleElementShowHandler(false);
  //         // console.log('No more data to fetch');
  //       } else if (response?.length > 0) {
  //         setdata((prevData) => [...prevData, ...response]);
  //         setCurrentPage((prevPage) => prevPage + 1);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     }
  //   });
  // }, [searchData]);

  const setSearchDataHandler = (data) => {
    setSearchData(data);
    // dispatch(setReduxSearchData(data));
  }

  return (
    <>
      <Layout>
        <Heading
          name="Items"
          searchData={searchData}
          modalToggle={modalToggle}
          setSearchData={setSearchDataHandler}
          mainSetCallApi={setCallApi}
          // DownloadReportHandler={DownloadReportHandler}
          breadCrumb1="search-stock"
          // qrModalOpen={qrModalOpen}
          qrModalToggle={qrModalToggle}
          setMainData={setdata}
          setCurrentPage={setCurrentPage}
          setPageLoading={setPageLoading}
        />
        <div className="card">
          {/* qr scanner start */}
          {/* qr scanner ends */}
          <div style={{ marginTop: "10px" }}>
            {/* <div>
            <button className="btn btn-primary">button</button>
          </div> */}
            {!isLoading && (
              <Nav tabs pills fill
                style={{ overflowX: "auto", overflowY: "hidden", minWidth: "120px" }}
                // className="border-bottom-0 mb-1 px-1 justify-content-lg-between justify-content-start"
                className="d-flex flex-nowrap border-bottom-0 mb-1 px-1"
              >
                {catagoryData?.map((data, index) => (
                  <NavItem
                    style={{ paddingY: "5px", paddingX: "0px", marginBottom: "2px", textAlign: "center", minWidth: "120px", fontWeight: "600" }}
                  >
                    <NavLink
                      // style={{padding:'10px 40px '}}
                      // onClick={function noRefCheck(){}}
                      className={tabOpen === index + 1 ? "active " : ""}
                      onClick={() => setTabValue(index + 1, data?.CODE)}
                    >
                      {data?.NAME}
                    </NavLink>
                  </NavItem>
                ))}
                {/* <Heading name="Items"
        modalToggle={modalToggle}
        setSearchData={setSearchData}
        mainSetCallApi={setCallApi}
        // DownloadReportHandler={DownloadReportHandler}
        // breadCrumb1="search-stock"
        setMainData={setdata}
        setCurrentPage={setCurrentPage}
      /> */}
                {/* <li class="nav-item w-30">
              <div class="input-group ">
                <form className="d-flex w-30"
                // onSubmit={submitHandler}
                >
                <input
                  type="text"
                  class="form-control"
                  style={{ borderRadius: "5px" }}
                  placeholder="Search here..."
                  // onChange={(e) => setSearchData(e.target.value)}
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary ml-2"
                    type="submit"
                    id="button-addon2"
                    // onClick={submitHandler}
                  >
                    Submit
                  </button>
                 
                </div>
                </form>
              </div>
            </li> */}
              </Nav>
            )}

            <TabContent activeTab={tabOpen}>
              <TabPane tabId={tabOpen}>
                <div className="table-responsive">
                  <table
                    className="table card-table display mb-4 shadow-hover table-responsive-lg table-hover"
                    style={{ minWidth: "100px" }}
                  >
                    <thead>
                      <tr>
                        {tableArray &&
                          tableArray?.map((item) => <th>{item}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {data && !isLoading && data?.length == 0 ? (
                        <h3
                          style={{
                            position: "absolute",
                            left: "40%",
                            padding: "10px",
                          }}
                        >
                          No data found
                        </h3>
                      ) : (
                        data?.map((data, index) => (
                          <tr style={{ cursor: "pointer" }} onClick={() => navigationFunction(
                            data?.Code ? data?.Code : data?.No
                          )}>
                            <td>{data?.Code}</td>
                            <td>{data?.Name}</td>
                            {data?.Customer?.Name && (
                              <td>{data?.Customer?.Name}</td>
                            )}
                            <td>{data?.CollectionCode}</td>
                            <td>{data?.QtyInv && +data?.QtyInv.toFixed(2)}</td>
                            <td>
                              {data?.BatchCount > 0 ? data?.BatchCount : 0}
                            </td>
                            {/* <td>
                              <PrimaryButton
                                name="view"
                                clickEvent={() =>
                                  navigationFunction(
                                    data?.Code ? data?.Code : data?.No
                                  )
                                }
                              />
                            </td> */}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  {isLoading && (
                    <h4 style={{ textAlign: "center" }}>Loading...</h4>
                  )}
                </div>
              </TabPane>
            </TabContent>
            {/* <Pagination
              activePage={currentPage}
              itemsCountPerPage={10}

              disabledClass="paginationDisableCustom"
              pageRangeDisplayed={5}
            // onChange={handlePageChange}
            /> */}
          </div>
        </div>
        {visibleElementshowHandler && !isLoading && <div ref={myRef} id="scroll"></div>}
        <SearchStockModal
          modalToggle={modalToggle}
          modalOpen={searchStockModalOpen}
          setSearchBrandData={setSearchBrandData}
          searchBrandData={searchBrandData}
          mainSetCallApi={setCallApi}
          setSearchCollectionData={setSearchCollectionData}
          searchCollectionData={searchCollectionData}
          categoryCode={categoryCode}
          setIsLoading={setIsLoading}
          setMainData={setdata}
          setCurrentPage={setCurrentPage}
        />
        <QrModal
          modalToggle={qrModalToggle}
          modalOpen={qrModalOpen}
          // videoInputRef={videoInputRef}
          handleError={handleError}
          // scannedText={scannedText}
          handleScan={handleScan}
          result={result}
        />
      </Layout>
      {pageLoading && <PageLoader />}
    </>
  );
};
export default SearchStock;
