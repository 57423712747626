import {
  CHECK_USER_LOGGED_IN,
  USER_LOGGED_IN_DETAILS,
  FETCH_USER_REQUEST,
  USER_TYPE,
  CART_DATA,
  SEARCH_DATA
} from "../types";

export const checkUserLoggedIn = (payload) => {
  return {
    type: CHECK_USER_LOGGED_IN,
    payload,
  };
};

export const setReduxSearchData = (payload) => {
  return {
    type: SEARCH_DATA,
    payload,
  };
};

export const cartData = (payload) => {
  return {
    type: CART_DATA,
    payload,
  }
};

export const setUsertype = (payload) => {
  return {
    type: USER_TYPE,
    payload,
  };
};

export const setUserLoggedInDetails = (payload) => {
  return {
    type: USER_LOGGED_IN_DETAILS,
    payload,
  };
};

export const fetchUserRequest = () => {
  return {
    type: FETCH_USER_REQUEST,
  };
};
