import './App.css';
import CommonRoutes from './Routes/CommonRoutes';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setUserLoggedInDetails, setUsertype } from "./store/actions/UserActions";

function App() {
   const dispatch = useDispatch();
   if (Cookies.get('F&ftToken')) {
      let tot = Cookies.get('F&ftToken');
      let userName = Cookies.get('F&FuserName');
      dispatch(setUsertype(userName));
      let decoded = jwt_decode(tot);
      dispatch(setUserLoggedInDetails(decoded));
   }

   return (
      <CommonRoutes />
   );
}

export default App;
