import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useInView } from "react-intersection-observer";
import { GetDataWithToken } from "../ApiHelper/ApiHelper";
import Layout from "../Components/Common/Layout";
import Heading from "../Components/Layouts/Heading";
import TableComponent from "../Components/Layouts/TableComponent";
import AddQuotationModal from "../Components/Modals/AddQuotationModal";
import OrderModal from "../Components/Modals/OrderModal";
import { toast } from "react-toastify";
import PageLoader from "../Components/Common/PageLoader";

const Quotation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quotationList, setQuotationList] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { ref: myRef, inView: visibleElement } = useInView();
  const [tabOpen, setTabOpen] = useState(1);
  const [statusCode, setStatusCode] = useState("CREATED");
  const [callApi, setCallApi] = useState(true);
  const [searchData, setSearchData] = useState('');
  const [openQuotationModal, SetOpenQuotationModal] = useState(false);
  const [openFilterModal, SetOpenFilterModal] = useState(false);
  const [customerCode, setCustomerCode] = useState('');
  const filterToggle = () => SetOpenFilterModal(!openFilterModal);
  const QuotationModalToggle = () => SetOpenQuotationModal(!openQuotationModal);
  const [date, setDate] = useState({
    fromDate: null,
    toDate: null,
  });
  const setTabValue = (value) => {
    setTabOpen(value);
    value === 2 ? setStatusCode('SENT') : setStatusCode('CREATED');
    setCallApi(true);
    setQuotationList([]);
    setIsLoading(true);
    setCurrentPage(1);
  }

  const tableHeading = ["Code", "Date", "Customer",
    // "Action"
  ];

  const filters = [];

  if (searchData) {
    filters.push(`SearchText:${searchData}`);
  }

  if (customerCode) {
    filters.push(`CustomerCode:${customerCode}`);
  }

  if (statusCode) {
    filters.push(`StatusCode:${statusCode}`);
  }

  if (date?.fromDate !== null && date?.fromDate !== undefined) {
    filters.push(`FromDate:${date?.fromDate}`);
  }

  if (date?.toDate !== null && date?.toDate !== undefined) {
    filters.push(`ToDate:${date.toDate}`);
  }

  const queryString = filters.length > 0 ? `sq/list?pageNo=${currentPage}&pageSize=10&filters=${filters.join(';')}` : `sq/list?pageNo=${currentPage}&pageSize=10`;


  useEffect(() => {
    if (visibleElement || callApi) {
      setIsLoading(true);
      GetDataWithToken(
        queryString
        // `sq/list?pageNo=${currentPage}&pageSize=10&filters=${searchData && `SearchText:${searchData};`}StatusCode:${statusCode};${date?.fromDate && `FromDate:${date?.fromDate};`}${date?.toDate && `ToDate:${date?.toDate};`}${customerCode && `CustomerCode:${customerCode};`}`
      )
        .then((response) => {
          if (response.status === 500) {
            toast.error(response.data);
            setIsLoading(false);
            setPageLoading(false);
            setCallApi(false);
          } else {
            setCurrentPage((prevPage) => prevPage + 1);
            setQuotationList(prevData => [...prevData, ...response]);
            setIsLoading(false);
            setPageLoading(false);
            setCallApi(false);
          }

        })
    }
  }, [visibleElement, callApi])

  return (<>

    <Layout>
      <Heading
        name="quotation"
        // breadCrumb1={"quotation"}
        searchData={searchData}
        modalToggle={filterToggle}
        setSearchData={setSearchData}
        searchData={searchData}
        mainSetCallApi={setCallApi}
        setMainData={setQuotationList}
        createQuotation={QuotationModalToggle}
        setCurrentPage={setCurrentPage}
        setPageLoading={setPageLoading}
      />
      <div className="card">
        {!isLoading && <Nav tabs pills fill>
          <NavItem>
            <NavLink
              style={{ fontWeight: "600" }}
              className={tabOpen === 1 ? "active" : ""}
              onClick={() => setTabValue(1)}
            >CREATED</NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ fontWeight: "600" }}
              className={tabOpen === 2 ? "active" : ""}
              onClick={() => setTabValue(2)}
            >SENT</NavLink>
          </NavItem>
        </Nav>}
        <TabContent activeTab={tabOpen}>
          <TabPane tabId={1}>
            <div>
              <TableComponent isLoading={isLoading} data={quotationList} quotation='yes' navigation="/quotation-detail" tableHeading={tableHeading} />
            </div>
          </TabPane>
          <TabPane tabId={2}>
            <div className="table-responsive">
              <TableComponent isLoading={isLoading} data={quotationList} navigation="/quotation-detail" quotation='yes' tableHeading={tableHeading} />
            </div>
          </TabPane>
        </TabContent>
        {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
        {quotationList.length >= 9 && !isLoading && <div ref={myRef} id="scroll"></div>}
      </div>
    </Layout>
    <OrderModal
      openModal={openFilterModal}
      modalToggle={filterToggle}
      setCustomerCode={setCustomerCode}
      customerCode={customerCode}
      mainCallApi={setCallApi}
      setDate={setDate}
      date={date}
      ledger={'ledger'}
      // setDeliveryName={setDeliveryName}
      // deliveryName={deliveryName}
      setIsLoading={setIsLoading}
      setMainData={setQuotationList}
      setCurrentPage={setCurrentPage}
    />
    <AddQuotationModal
      openModal={openQuotationModal}
      modalToggle={QuotationModalToggle} />
    {pageLoading && <PageLoader />}
  </>
  )
}
export default Quotation;